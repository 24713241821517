<form *ngIf="muestraCobertura == true" [formGroup]="firstFormGroup">
    <ng-container *ngIf="pdv?.info?.username !== 'demo_pdv'">
        <h6 class="resultados-norm">Resultados:</h6>

        <!--LOCALIDAD-->
        <h6 class="localidad-norm">Localidad:</h6>
        <div class="radio-btn-group" [formGroup]="firstFormGroup">
            <div class="radio" *ngFor='let localidad of localidades'>
                <input id="{{localidad}}" formControlName="localidadCobertura" type="radio" name="localidadCobertura"
                    value="{{localidad}}">
                <label for="{{localidad}}" (click)="onChangeLocalidad(localidad)">{{localidad}}</label>
                <mat-error *ngIf="errorHandling('localidadCobertura', 'required')">
                    Campo obligatorio.
                </mat-error>
            </div>
            <button [disabled]="isLoading" class="reset_radio" (click)="onReset(firstFormGroup)">Otra localidad</button>
        </div>
        <!--DIRECCIONES SUGERIDAS-->
        <div class="radio" id="direcciones_sugeridas" *ngIf="direccionesSugeridas !== null ">
            <h6 class="direccion-norm">Dirección seleccionada:</h6>
            <div class="radio-btn-group" [formGroup]="firstFormGroup">
                <div class="radio" *ngFor='let dir of direccionesSugeridas'>
                    <input id="{{dir.texto}}" formControlName="direccionesCobertura" type="radio"
                        name="direccionesCobertura" value="{{dir.texto}}" (click)="onChangeDireccionesSugeridas(dir)">
                    <label for="{{dir.texto}}">
                        <span>
                            {{dir.streetType }}
                            {{(dir.streetName ? ' , ' + dir.streetName : '')}}
                            {{(dir.streetNr ? ' , ' + dir.streetNr : '')}}
                            {{(dir.postCode ? ' , ' + dir.postCode : '')}}
                        </span>
                    </label>
                    <mat-error *ngIf="errorHandling('direccionesCobertura', 'required')">
                        Campo obligatorio.
                    </mat-error>
                </div>
                <button [disabled]="isLoading" class="reset_radio" (click)="onReset(firstFormGroup)">Otra
                    dirección</button>
            </div>
        </div>


        <div id="consulta_cobertura" class="row">
            <!----- PLANTA ------->
            <div id="planta" class="col-lg-4 planta  mt-4" *ngIf="plantas !== null && plantas.length != 0 ">
                <mat-form-field [formGroup]="firstFormGroup">
                    <mat-label>Selecciona planta</mat-label>
                    <select formControlName="plantaCobertura" matNativeControl (change)="onChangePlanta()">
                        <option value="">Selecciona</option>
                        <option *ngFor="let planta of plantas" value="{{planta}}">{{planta}}</option>
                    </select>
                    <mat-error *ngIf="errorHandling('plantaCobertura', 'required')">
                        Campo obligatorio.
                    </mat-error>
                </mat-form-field>
            </div>

            <div id="contenedor-normalizacion-vertical" class="col-lg-8">
                <!--BIS-->
                <div *ngIf="bisOption !== null && bisOption.length != 0 " class="col-lg-12 mt-4">
                    <!--vista radio-->
                    <ng-template [ngIf]="bisOption.length < 5" [ngIfElse]="selectBis">
                        <label for="validationCustom01" class="label-select-piso">Selecciona bis</label>
                        <div class="radio-btn-group piso" [formGroup]="firstFormGroup">
                            <div class="radio" *ngFor="let bis of bisOption">
                                <input id="streetNrSuffix-{{bis}}" formControlName="bisCobertura" type="radio"
                                    name="bisCobertura" value="{{bis}}">
                                <label for="streetNrSuffix-{{bis}}"
                                    (click)="onSelectOpcion('streetNrSuffix',bis)">{{bis}}</label>
                            </div>
                        </div>
                        <mat-error *ngIf="errorHandling('bisCobertura', 'required')">
                            Campo obligatorio.
                        </mat-error>
                    </ng-template>
                    <!--FIN vista radio-->
                    <!--vista select-->
                    <ng-template #selectBis>
                        <mat-form-field [formGroup]="firstFormGroup">
                            <mat-label>Bis</mat-label>
                            <select matNativeControl formControlName="bisCobertura"
                                (change)="onSelectOpcion('streetNrSuffix',$event.target.value)">
                                <option value="">Selecciona</option>
                                <option *ngFor="let bis of bisOption" value="{{bis}}">{{bis}}</option>
                            </select>
                        </mat-form-field>
                        <mat-error *ngIf="errorHandling('bisCobertura', 'required')">
                            Campo obligatorio.
                        </mat-error>
                    </ng-template>
                    <!--FIN vista select-->
                </div>
                <!--Bloque-->
                <div *ngIf="bloqueOption !== null && bloqueOption.length != 0 " class="col-lg-12 mt-4">
                    <!--vista radio-->
                    <ng-template [ngIf]="bloqueOption.length < 5" [ngIfElse]="selectBloque">
                        <label for="validationCustom01" class="label-select-piso">Selecciona bloque</label>
                        <div class="radio-btn-group piso" [formGroup]="firstFormGroup">
                            <div class="radio" *ngFor="let bloque of bloqueOption">
                                <input id="subUnitType-{{bloque}}" formControlName="bloqueCobertura" type="radio"
                                    name="bloqueCobertura" value="{{bloque}}">
                                <label for="subUnitType-{{bloque}}"
                                    (click)="onSelectOpcion('subUnitType',bloque)">{{bloque}}</label>
                            </div>
                        </div>
                        <mat-error *ngIf="errorHandling('bloqueCobertura', 'required')">
                            Campo obligatorio.
                        </mat-error>
                    </ng-template>
                    <!--FIN vista radio-->
                    <!--vista select-->
                    <ng-template #selectBloque>
                        <mat-form-field [formGroup]="firstFormGroup">
                            <mat-label>Bloque</mat-label>
                            <select matNativeControl formControlName="bloqueCobertura"
                                (change)="onSelectOpcion('subUnitType',$event.target.value)">
                                <option value="">Selecciona</option>
                                <option *ngFor="let bloque of bloqueOption" value="{{bloque}}">{{bloque}}</option>
                            </select>
                        </mat-form-field>
                        <mat-error *ngIf="errorHandling('bloqueCobertura', 'required')">
                            Campo obligatorio.
                        </mat-error>
                    </ng-template>
                    <!--FIN vista select-->
                </div>
                <!--Portal-->
                <div *ngIf="portalOption !== null && portalOption.length != 0 " class="col-lg-12 mt-4">
                    <!--vista radio-->
                    <ng-template [ngIf]="portalOption.length < 5" [ngIfElse]="selectPortal">
                        <label for="validationCustom01" class="label-select-piso">Selecciona portal</label>
                        <div class="radio-btn-group piso" [formGroup]="firstFormGroup">
                            <div class="radio" *ngFor="let portal of portalOption">
                                <input id="buildingName-{{portal}}" formControlName="portalCobertura" type="radio"
                                    name="portalCobertura" value="{{portal}}">
                                <label for="buildingName-{{portal}}"
                                    (click)="onSelectOpcion('buildingName',portal)">{{portal}}</label>
                            </div>
                        </div>
                        <mat-error *ngIf="errorHandling('portalCobertura', 'required')">
                            Campo obligatorio.
                        </mat-error>
                    </ng-template>
                    <!--FIN vista radio-->
                    <!--vista select-->
                    <ng-template #selectPortal>
                        <mat-form-field [formGroup]="firstFormGroup">
                            <mat-label>Portal</mat-label>
                            <select matNativeControl formControlName="portalCobertura"
                                (change)="onSelectOpcion('buildingName',$event.target.value)">
                                <option value="">Selecciona</option>
                                <option *ngFor="let portal of portalOption" value="{{portal}}">{{portal}}</option>
                            </select>
                        </mat-form-field>
                        <mat-error *ngIf="errorHandling('portalCobertura', 'required')">
                            Campo obligatorio.
                        </mat-error>
                    </ng-template>
                    <!--FIN vista select-->
                </div>
                <!--Escalera-->
                <div *ngIf="escaleraOption !== null && escaleraOption.length != 0 " class="col-lg-12 mt-4">
                    <!--vista radio-->
                    <ng-template [ngIf]="escaleraOption.length < 5" [ngIfElse]="selectEscalera">
                        <label for="validationCustom01" class="label-select-piso">Selecciona escalera</label>
                        <div class="radio-btn-group piso" [formGroup]="firstFormGroup">
                            <div class="radio" *ngFor="let escalera of escaleraOption">
                                <input id="stairCase-{{escalera}}" formControlName="escaleraCobertura" type="radio"
                                    name="escaleraCobertura" value="{{escalera}}">
                                <label for="stairCase-{{escalera}}"
                                    (click)="onSelectOpcion('stairCase',escalera)">{{escalera}}</label>
                            </div>
                        </div>
                        <mat-error *ngIf="errorHandling('escaleraCobertura', 'required')">
                            Campo obligatorio.
                        </mat-error>
                    </ng-template>
                    <!--FIN vista radio-->
                    <!--vista select-->
                    <ng-template #selectEscalera>
                        <mat-form-field [formGroup]="firstFormGroup">
                            <mat-label>Escalera</mat-label>
                            <select matNativeControl formControlName="escaleraCobertura"
                                (change)="onSelectOpcion('staircase',$event.target.value)">
                                <option value="">Selecciona</option>
                                <option *ngFor="let escalera of escaleraOption" value="{{escalera}}">{{escalera}}
                                </option>
                            </select>
                        </mat-form-field>
                        <mat-error *ngIf="errorHandling('escaleraCobertura', 'required')">
                            Campo obligatorio.
                        </mat-error>
                    </ng-template>
                    <!--FIN vista select-->
                </div>
                <!--Tipo puerta-->
                <div *ngIf="tipoPuertaOption !== null && tipoPuertaOption.length != 0 " class="col-lg-12 mt-4">
                    <!--vista radio-->
                    <ng-template [ngIf]="tipoPuertaOption.length < 5" [ngIfElse]="selectTipoPuerta">
                        <label for="validationCustom01" class="label-select-piso">Selecciona tipo puerta</label>
                        <div class="radio-btn-group piso" [formGroup]="firstFormGroup">
                            <div class="radio" *ngFor="let tipoPuerta of tipoPuertaOption">
                                <input id="doorType-{{tipoPuerta}}" formControlName="tipoPuertaCobertura" type="radio"
                                    name="tipoPuertaCobertura" value="{{tipoPuerta}}">
                                <label for="doorType-{{tipoPuerta}}"
                                    (click)="onSelectOpcion('doorType',tipoPuerta)">{{tipoPuerta}}</label>
                            </div>
                        </div>
                        <mat-error *ngIf="errorHandling('tipoPuertaCobertura', 'required')">
                            Campo obligatorio.
                        </mat-error>
                    </ng-template>
                    <!--FIN vista radio-->
                    <!--vista select-->
                    <ng-template #selectTipoPuerta>
                        <mat-form-field [formGroup]="firstFormGroup">
                            <mat-label>Tipo Puerta</mat-label>
                            <select matNativeControl formControlName="tipoPuertaCobertura"
                                (change)="onSelectOpcion('doorType',$event.target.value)">
                                <option value="">Selecciona</option>
                                <option *ngFor="let tipoPuerta of tipoPuertaOption" value="{{tipoPuerta}}">
                                    {{tipoPuerta}}</option>
                            </select>
                        </mat-form-field>
                        <mat-error *ngIf="errorHandling('tipoPuertaCobertura', 'required')">
                            Campo obligatorio.
                        </mat-error>
                    </ng-template>
                    <!--FIN vista select-->
                </div>
                <!--Puerta-->
                <div *ngIf="puertaOption !== null && puertaOption.length != 0 " class="col-lg-12 mt-4">
                    <!--vista radio-->
                    <ng-template [ngIf]="puertaOption.length < 5" [ngIfElse]="selectPuerta">
                        <label for="validationCustom01" class="label-select-piso">Selecciona puerta</label>
                        <div class="radio-btn-group piso" [formGroup]="firstFormGroup">
                            <div class="radio" *ngFor="let puerta of puertaOption">
                                <input id="door-{{puerta}}" formControlName="puertaCobertura" type="radio"
                                    name="puertaCobertura" value="{{puerta}}">
                                <label for="door-{{puerta}}" (click)="onSelectOpcion('door',puerta)">{{puerta}}</label>
                            </div>
                        </div>
                        <mat-error *ngIf="errorHandling('puertaCobertura', 'required')">
                            Campo obligatorio.
                        </mat-error>
                    </ng-template>
                    <!--FIN vista radio-->
                    <!--vista select-->
                    <ng-template #selectPuerta>
                        <mat-form-field [formGroup]="firstFormGroup">
                            <mat-label>Puerta</mat-label>
                            <select matNativeControl formControlName="puertaCobertura"
                                (change)="onSelectOpcion('door',$event.target.value)">
                                <option value="">Selecciona</option>
                                <option *ngFor="let puerta of puertaOption" value="{{puerta}}">{{puerta}}</option>
                            </select>
                        </mat-form-field>
                        <mat-error *ngIf="errorHandling('puertaCobertura', 'required')">
                            Campo obligatorio.
                        </mat-error>
                    </ng-template>
                    <!--FIN vista select-->
                </div>
                <!--//////-->
            </div>
        </div>

        <div id="Errores_ninguna" *ngIf="muestraErrorPeticion == true">
            <mat-error>
                <p class="alert alert-warning">
                    {{msgErrorPeticion}}
                </p>
            </mat-error>
        </div>
    </ng-container>
    <div class="row mt-3">
        <div id="botones_cobertura" class="col-12 col-md">
            <button [disabled]="isLoading" type="submit" class="btn btn-black--outline mt-1 mb-1"
                (click)="onConsultarCobertura()" *ngIf="muestraBotonesCobertura === true">
                <span *ngIf="isLoading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                {{textDefault}}
            </button>
        </div>
        <div id="content_ninguna_anteriores" class="col-12 col-md-auto mt-3" *ngIf="muestraBotonNinguna === true">
            <span (click)="onReset(firstFormGroup)" class="text-direccion">Comprobar otra dirección</span>
        </div>
    </div>

    <ng-template #cobertura let-modal>
        <div class="modal-header">
            <h5 class="modal-title font-weight-bold ml-3 mt-3" id="exampleModalLabel">Consulta de cobertura</h5>
            <button type="button" class="close close-m2" data-dismiss="modal" aria-label="Close"
                (click)="modal.dismiss('Cross click')">
                <img src="./assets/img/x-black.svg" width="18" class="close-aviso--1">
            </button>
        </div>

        <div class="modal-body">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <h6 class="alert-heading mt-2 orange"><b>No tenemos datos de tu dirección exacta</b></h6>
                        <p>Si quieres continuar hacemos una consulta aproximada con la dirección que nos has dado hasta
                            ahora</p>
                        <hr>

                        <button (click)="onConsultarNingunaAnteriores()" class="btn btn-black--outline mt-1 mb-3"
                            type="button">Continuar</button>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

</form>