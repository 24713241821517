import {Component, HostListener, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {DigoService} from '../../../services/digo.service';
import {HttpErrorResponse} from '@angular/common/http';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import {MasmovilService} from '../../services/masmovil.service';
import {MasterAllBrand, MasterAllResponse, MasterAllStatus} from '../../models/master-all-response';
import {OrderListStats, OrderListStatsResponse} from '../../models/order-list-stats-response';
import {Order, OrderListFilterResponse, OrderMobile} from '../../models/order-list-filter-response';
import {GenericList, listSortByDate, listType} from '../../../shared/constantes';
import * as moment from 'moment';
import {UserNotFoundComponent} from '../../../shared/components/user-not-found/user-not-found.component';
import {MatDialog} from '@angular/material/dialog';
import {OrderListFilters, OrderListRequest} from '../../models/order-list-request';
import {Ipdv} from '../../../shared/models/pdvResponse';

const BRAND: string = 'masmovil';
const ELEMENTS_BY_PAGE = 50;

@Component({
    selector: 'app-masmovil-listado-activaciones',
    templateUrl: './masmovil-listado-activaciones.component.html',
    styleUrls: ['./masmovil-listado-activaciones.component.css', '../../../../assets/css/masmovil-theme.css']
})
export class MasmovilListadoActivacionesComponent implements OnInit {
    public isCollapsed: boolean = true;
    public currentMonth: string;
    public isAlertView: boolean = false;
    public minDate: Date;
    public maxDate: Date;
    public formGroup: FormGroup;

    public loaded: boolean = false;
    public lastPage: number = 0;
    public orderListRequest: OrderListRequest = {
        limit: ELEMENTS_BY_PAGE,
        page: 1,
        order: {
            tramited_date: 'desc'
        }
    };

    public pdv: Ipdv;

    public brand: MasterAllBrand;
    public rates: string[];
    public statuses: MasterAllStatus[] = [];
    public stats?: OrderListStats;
    public orders: Order[] = [];
    public ordersFiltered: Order[] = [];

    public listType: GenericList[] = listType;
    public listSortByDate: GenericList[] = listSortByDate;
    public errorSearch: boolean = false;

    private automaticLoad: boolean = true;

    constructor(
        public dialog: MatDialog,
        private formBuilder: FormBuilder,
        private digoService: DigoService,
        private masmovilService: MasmovilService
    ) {
    }

    ngOnInit(): void {
        this.doKeepAlive();
        this.currentMonth = moment().locale('es').format('MMMM');
        this.formGroup = this.formBuilder.group({
            full_name: [''],
            document: [''],
            msisdn_movil: [''],
            min_date: [''],
            max_date: [''],
            status: [''],
            contract_type: [''],
            sort_by_date: ['desc']
        });

        this.digoService.getPDV().subscribe((data: Ipdv) => {
            if (data) {
                this.pdv = data;
            }
        });
        this.masmovilService.getMasterAllFromService(BRAND).subscribe((data: MasterAllResponse) => {
            this.rates = data.msg.rates;
            this.brand = data.msg.brands.find(b => b.code === BRAND.toUpperCase());
            this.statuses = data.msg.statuses;
        });
        this.masmovilService.getOrderListStats(BRAND).subscribe((data: OrderListStatsResponse) => {
            this.stats = data.msg;
        });
        this.getOrders();
    }

    @HostListener('window:scroll', ['$event'])
    doSomethingOnWindowScroll(event: Event) {
        if (this.automaticLoad) {
            const element: any = event.srcElement;
            const scrollOffset = element.children[0].scrollTop;
            const windowHeight = document.documentElement.scrollHeight - window.innerHeight - 200;

            if (scrollOffset > windowHeight && windowHeight > 0) {
                console.log('Cargando siguiente página. A partir de ahora se carga en manual.');
                this.automaticLoad = false;
                //this.nextPage();
            }
        }
    }

    getOrders() {
        this.errorSearch = false;

        this.masmovilService.getOrderListFilter(BRAND, this.orderListRequest).subscribe((data: OrderListFilterResponse) => {
            this.loaded = true;
            this.orders = data.msg.data;
            this.ordersFiltered = this.orders;
            //this.orders = this.getOrdersMock();
            //this.ordersFiltered = this.getOrdersMock();
            this.lastPage = data.msg.last_page;
        }, error => {
            this.loaded = true;
            this.errorSearch = true;

            if (error && error.error && error.error.error && error.error.error.errorCode !== null && error.error.error.errorCode === 462) {
                this.dialog.open(UserNotFoundComponent, {
                    width: '350px',
                    disableClose: true,
                    data: {
                        marca: 'MásMóvil'
                    }
                });
            }
        });
    }

    getOrdersMock() {
        const order: Order = {
            order_id: 312,
            brand_id: 2,
            pdv_id: 4,
            customer_full_name: 'FRANCIS ALBERTO DANIEL',
            document_number: 'Y8949404A',
            order_code: '12123123',
            broadbands: [
                {
                    msisdn: '865790266',
                    rate: 'FIBRA 1GB MASMOVIL',
                    is_portability: false,
                    terminal: null,
                    installation_address: null,
                    status_id: 9,
                    status: 'Terminado',
                    tramitation_date: '2024-11-11'
                }
            ],
            mobiles: [
                {
                    msisdn: '643954171',
                    rate: 'TARIFA MAS 10GB AD NC',
                    is_portability: true,
                    terminal: null,
                    status_id: 9,
                    status: 'Terminado',
                    tramitation_date: '2024-11-11',
                    contract_type: 'POSTPAID'
                },
                {
                    msisdn: '643954000',
                    rate: 'TARIFA MAS 10GB AD NC',
                    is_portability: true,
                    terminal: null,
                    status_id: 9,
                    status: 'Terminado',
                    tramitation_date: '2024-11-11',
                    contract_type: 'POSTPAID'
                }
            ],
            services: [
                {
                    msisdn: '865790266',
                    rate: 'TV PREMIUM',
                    is_portability: false,
                    terminal: 'TV BOX 4K ANDROID',
                    status_id: 9,
                    status: 'Terminado',
                    tramitation_date: '2024-11-11'
                },
                {
                    msisdn: '865790266',
                    rate: 'TARIFA FIJO',
                    is_portability: false,
                    terminal: null,
                    status_id: 9,
                    status: 'Terminado',
                    tramitation_date: '2024-11-11'
                }
            ],
            type: 'convergent',
        };
        const length = 20;

        return Array.from({length}, () => ({...order}));
    }

    getOrderTramitationDate(order: Order) {
        if (order.tramited_at === null) {
            return '--';
        }

        const oldestDate = new Date(order.tramited_at);

        return new Intl.DateTimeFormat('es-ES', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        }).format(oldestDate);
    }

    getActivationThisMonth() {
        this.isAlertView = false;
        this.resetRequest();
        this.orderListRequest.filters = {
            statuses: [this.statuses.find(s => s.translation === 'Activo')?.translation],
            tramited_date: 'gte|' + moment().startOf('month').format('YYYY-MM-DD 00:00:00')
        };
        this.getOrders();
    }

    getRequestThisMonth() {
        this.isAlertView = false;
        this.resetRequest();
        this.orderListRequest.filters = {
            tramited_date: 'gte|' + moment().startOf('month').format('YYYY-MM-DD 00:00:00')
        };
        this.getOrders();
    }

    getAlertThisMonth(page: number) {
        //ToDo
    }

    getSuspendedThisMonth() {
        //ToDo
    }

    searchClient(text: string) {
        this.ordersFiltered = this.orders.filter(order => order.customer_full_name.toLowerCase().includes(text.toLowerCase()));
    }

    advanceSearch() {
        this.isAlertView = false;
        const formData = this.formGroup.getRawValue();
        const filters: OrderListFilters = {};

        if (formData.full_name !== '') {
            filters.customer_full_name = formData.full_name;
        }
        if (formData.document !== '') {
            filters.customer_document = formData.document;
        }
        if (formData.msisdn_movil !== '') {
            filters.mobile_msisdn = formData.msisdn_movil;
        }
        if (formData.status) {
            filters.statuses = [formData.status];
        }
        if (formData.contract_type) {
            filters.type = formData.contract_type;
        }
        if (formData.sort_by_date) {
            this.orderListRequest.order.tramited_date = formData.sort_by_date;
        }
        if (formData.min_date != null && formData.max_date != null && formData.min_date !== '' && formData.max_date !== '') {
            filters.tramited_date = 'bt|' + moment(formData.min_date).format('YYYY-MM-DD') + '|' + moment(formData.max_date).format('YYYY-MM-DD');
        } else if (formData.min_date != null && formData.min_date !== '') {
            filters.tramited_date = 'gte|' + moment(formData.min_date).format('YYYY-MM-DD');
        } else if (formData.max_date != null && formData.max_date !== '') {
            filters.tramited_date = 'lte|' + moment(formData.max_date).format('YYYY-MM-DD');
        }

        this.orderListRequest.page = 1;
        this.orderListRequest.filters = filters;
        this.orders = [];
        this.ordersFiltered = [];
        this.automaticLoad = true;

        this.getOrders();
    }

    setMinDate(event: MatDatepickerInputEvent<Date>): void {
        this.minDate = event.value;
    }

    setMaxDate(event: MatDatepickerInputEvent<Date>): void {
        this.maxDate = event.value;
    }

    nextPage(): void {
        if (this.orderListRequest.page < this.lastPage) {
            this.orderListRequest.page += 1;
            this.isAlertView ? this.getAlertThisMonth(this.orderListRequest.page) : this.getOrders();
        }
    }

    loadMore(): void {
        this.nextPage();
    }

    doKeepAlive() {
        this.digoService.keepAlive().subscribe({
            next: (resp: any) => {
                if (!resp || resp !== 200) {
                    this.digoService.goLogin();
                }
            }
            , error: (error: HttpErrorResponse) => {
                this.digoService.goLogin();
            }
        });
    }

    private resetRequest(): void {
        this.orders = [];
        this.ordersFiltered = [];
        this.loaded = false;
        this.orderListRequest.limit = ELEMENTS_BY_PAGE;
        this.orderListRequest.page = 1;
        this.orderListRequest.order.tramited_date = 'desc';
    }

    getTitleLine(mobile: OrderMobile) {
        if (mobile === undefined) {
            return '';
        }

        return mobile?.is_portability ? `Portabilidad móvil` : 'Alta móvil';
    }

    showMsisdnTerminal(order) {
        const msisdn = order?.services[0]?.msisdn && order?.services[0]?.msisdn !== 'PENDIENTE DE ASIGNAR' ? order.services[0].msisdn : '';
        const terminal = order.services[0].terminal ? order.services[0].terminal : 'Sin Terminal';

        return msisdn !== '' ? `${msisdn} - ${terminal}` : terminal;
    }
}
