import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MyaddressService {

    private loadingMyAddress: Subject<boolean> = new Subject<boolean>();

    constructor() { }

    public interceptFetchRequests() {
        const originalFetch = window.fetch;
        const that = this
        window.fetch = async (input: RequestInfo, init?: RequestInit): Promise<Response> => {
            const url = typeof input === 'string' ? input : input.url;
            const targetUrl: string = '/myaddressBack/';

            if (url.includes(targetUrl)) {
                that.setLoadingMyAddress(true);

                try {
                    const response = await originalFetch(input, init);

                    const clonedResponse = response.clone();
                    clonedResponse.json().then((data) => {
                        that.setLoadingMyAddress(false);
                    });

                    return response;
                } catch (error) {
                    that.setLoadingMyAddress(false);
                    throw error;
                }
            }

            return originalFetch(input, init);
        }
    }

    public setLoadingMyAddress(data) {
        this.loadingMyAddress.next(data);
    }

    public getLoadingMyAddress() {
        return this.loadingMyAddress.asObservable();
    }
}
