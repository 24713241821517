export const dayToShowCategoryRappelPreviousMonth = 3;

export interface IrappelResponse {
    current: {
        text: string;
        activations_text: string;
        min_activations: number;
        max_activations: number;
        level_index: string;
        current_activations: number;
        next_level: {
            text: string;
            activations_text: string;
            min_activations: number;
            max_activations: number;
            level_index: string;
        }
        activations_per_month?: any;
        are_there_activations_in_both_months: boolean;
    },
    previous_month: {
        text: string;
        activations_text: string;
        min_activations: number;
        max_activations: number;
        level_index: string;
        current_activations: number;
        next_level: {
            text: string;
            activations_text: string;
            min_activations: number;
            max_activations: number;
            level_index: string;
        }
    },
    master: IrappelMaster[]
}

export interface IrappelMaster {
    text: string;
    activations_text: string;
    min_activations: number;
    max_activations: number;
    level_index: string;
}

export interface Irappel {
    mensual: IrappelResponse;
    bimensual: IrappelResponse;
}