<div *ngIf="!loaded && !orderNotFound" class="mt-5 mb-5">
    <h5 align="center" class="tar-name-preload">Obteniendo orden...</h5>

    <div align="center">
        <img class="" src="../../../../assets/img/spinner-pepephone.svg" width="30" alt="">
    </div>
</div>

<div class="container content mb-2" *ngIf="loaded">
    <!-- Titulo -->
    <div class="row">
        <div class="col-md-12">
            <h5 class="title-step mt-4" align="center">
                Resumen de la activación
            </h5>
        </div>
    </div>

    <!-- Descripcion rapida -->
    <div class="row mt-3 mb-2">
        <div class="col-md-12">
            <div class="jumbotron-norm-r mb-3">
                <div class="row ml-1">
                    <div class="col-lg-2">
                        <h5 align="left" class="tar-name-6 mt-2 mb-1">Nº de contrato</h5>
                        <h5 align="left" class="tar-name-7 mt-1">{{ order?.order_code }}</h5>
                    </div>
                    <!-- fecha tramitacion -->
                    <div class="col-lg-3">
                        <h5 align="left" class="tar-name-6 mt-2 mb-1">Fecha</h5>
                        <h5 align="left" class="tar-name-7 mt-1">{{ getOrderTramitationDate() }}</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Cliente -->
    <div class="row mb-2">
        <div class="col-md-12">
            <div class="card mb-4">
                <div class="card-header">
                    <div class="d-flex justify-content-between align-items-center">
                        <div>
                            <h6 class="subtitulo-pack2">
                                <b>Cliente</b>
                            </h6>
                        </div>
                    </div>
                </div>

                <div class="card-body bg-white">
                    <div class="row mt-4">
                        <div class="col-lg-8">
                            <!-- Nombre cliente -->
                            <h5 class="title-list ml-3">
                                <span class="text-list">
                                    <b>{{ order.customer_full_name }} </b>
                                <br><br>
                                </span>
                                <!-- Fecha de solicitud -->
                                <span class="font-list">
                                    <img src="./assets/img/clock.svg" class="img-fluid" width="13">
                                    Fecha de solicitud: <b>{{ getOrderTramitationDate() }}</b>
                                </span>
                                <br>
                                <!-- Tipo de alta -->
                                <span class="font-list">
                                    <img src="./assets/img/package2.svg" class="img-fluid" width="13">
                                    Tipo de alta:
                                    <b *ngIf="order.type === 'broadband'">Alta fibra</b>
                                    <b *ngIf="order.type === 'convergent'">
                                        Alta fibra + {{ order.mobiles.length + (order.mobiles.length > 1 ? ' líneas móviles' : ' línea móvil') }}
                                        <span *ngIf="order.services.length > 0">
                                             + {{ order.services.length + (order.services.length > 1 ? ' servicios' : ' servicio') }}
                                        </span>
                                    </b>
                                    <b *ngIf="order.type === 'mobile'">
                                        {{ order.mobiles.length + (order.mobiles.length > 1 ? ' líneas móviles' : ' línea móvil') }}
                                    </b>
                                    <b *ngIf="order.type === 'sva'">
                                        {{ order.services.length + (order.services.length > 1 ? ' servicios' : ' servicio') }}
                                    </b>
                                </span>

                                <span class="font-list" *ngIf="order.type === 'broadband' || order.type === 'mobile' || order.type === 'convergent'">
                                    <br>
                                    <img src="./assets/img/package2.svg" class="img-fluid" width="13">
                                    Pedido convergente: <b>{{ (order.type === 'convergent' || (order.broadbands.length > 0 && order.broadbands[0].is_convergent) || (order.mobiles.length > 0 && order.mobiles[0].is_convergent) ) ? 'Sí' : 'No' }} </b>
                                </span>
                            </h5>
                        </div>

                        <div align="right" class="col-lg-4">
                            <div class="col-lg-12 mb-2">

                                <span class="badge badge-pill badge-pepephone"
                                      *ngIf="(order.type === 'convergent' || order.type === 'broadband') && order.broadbands.length > 0">
                                      <span>
                                        Fibra {{ order.broadbands[0].rate }}
                                    </span>
                                </span>
                            </div>
                            <div class="col-lg-12 mb-2" *ngFor="let mobile of order.mobiles; index as i">
                                <span class="badge badge-pill badge-pepephone" *ngIf="mobile.rate">
                                    {{ mobile.rate }}
                                </span>
                            </div>
                            <div class="col-lg-12 mb-2" *ngFor="let service of order.services; index as i">
                                <span class="badge badge-pill badge-pepephone" *ngIf="service.rate">
                                    {{ service.rate }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Resumen tramitacion -->
    <div class="card mb-3">
        <div class="card-header bg-rm">
            <div class="d-flex justify-content-between align-items-center">
                <div>
                    <h6 class="subtitulo-pack2">
                        <img src="./assets/img/file-text-negro.svg" class="img-fluid ico-title mr-2" width="13">
                        <b>Resumen de la tramitación</b>
                    </h6>
                </div>
            </div>
        </div>

        <div class="bg-white">
            <div class="row">
                <div class="col-md-12">
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item cuerpo-pack-2">

                            <!-- RESUMEN MOVIL PACK -->
                            <div *ngIf="(order.type === 'convergent' || order.type === 'broadband')">
                                <div class="resumen d-flex justify-content-between align-items-center mt-2">
                                    <h5 class="tar-name-7 mt-2 text-left">
                                        <b>Alta Fibra</b>
                                    </h5>
                                </div>

                                <div class="resumen d-flex justify-content-between align-items-center">
                                    <span class="sidebar-info4 simyo"> Conceptos</span>
                                    <small class="text-muted"></small>
                                </div>

                                <div class="resumen d-flex justify-content-between align-items-center">
                                    <!-- tarifa -->
                                    <span class="sidebar-info5">
                                    <img src="./assets/img/package2.svg" class="img-fluid" width="13">
                                        Fibra {{ order.broadbands[0].rate }}
                                </span>
                                    <small class="text-muted" *ngIf="order.broadbands[0].rate_price">
                                        <!-- Precio tarifa -->
                                        <span class="sidebar-info3">
                                        <b><!-- Precio -->{{ order.broadbands[0].rate_price|toFixedDecimals }}€</b>
                                    </span>
                                    </small>
                                </div>

                                <div class="resumen d-flex justify-content-between align-items-center pl-3">
                                <span class="sidebar-info5">
                                    Estado fibra:
                                    <span>
                                        <b>{{ order.broadbands[0].status }}</b>
                                    </span>
                                </span>
                                </div>

                                <div class="resumen d-flex justify-content-between align-items-center pl-3" *ngIf="order?.broadbands[0]?.msisdn && order?.broadbands[0]?.msisdn !== 'PENDIENTE DE ASIGNAR' || order?.services[0]?.msisdn && order?.services[0]?.msisdn !== 'PENDIENTE DE ASIGNAR'">
                                    <span class="sidebar-info5">
                                        {{ order.broadbands[0].is_portability ? 'Portabilidad ' : 'Alta ' }} línea fija:
                                        <span>
                                            <b>{{ order?.broadbands[0]?.msisdn || order?.services[0]?.msisdn }}</b>
                                        </span>
                                    </span>
                                </div>

                                <div class="resumen d-flex justify-content-between align-items-center pl-3">
                                <span class="sidebar-info5">
                                    Fecha de activación: <b> {{ order.broadbands[0].activation_date ?? '-' }}</b>
                                </span>
                                </div>
                                <hr *ngIf="order.mobiles.length > 0 || order.services.length > 0">
                            </div>

                            <!-- RESUMEN LÍNEA ADICIONAL -->
                            <div *ngFor="let mobile of order.mobiles; index as i; let isLast = last">
                                <hr *ngIf="order.mobiles.length > 1 && i != 0">

                                <div class="resumen d-flex justify-content-between align-items-center mt-2">
                                    <h5 class="tar-name-7 mt-2 text-left">
                                        <b>{{ (i + 1) + 'ª línea móvil' }}</b>
                                    </h5>
                                </div>

                                <!-- CONCEPTOS -->
                                <div class="resumen d-flex justify-content-between align-items-center">
                                    <span class="sidebar-info4 simyo"> Conceptos</span>
                                    <small class="text-muted">
                                    </small>
                                </div>

                                <!-- Tipo tarifa movil -->
                                <div class="resumen d-flex justify-content-between align-items-center mt-2">
                                <span class="sidebar-info5">
                                    <img src="./assets/img/phone.svg" class="img-fluid" width="13">
                                    {{ getTitleTypeMobile(mobile) }}
                                </span>
                                </div>

                                <!-- Precio tarifa movil -->
                                <div class="resumen d-flex justify-content-between align-items-center">
                                    <!-- tarifa -->
                                    <span class="sidebar-info5">
                                    <img src="./assets/img/package2.svg" class="img-fluid" width="13">
                                    <span> {{ mobile.rate }}</span>
                                </span>
                                    <small class="text-muted" *ngIf="mobile.rate_price">
                                        <!-- Precio tarifa -->
                                        <span class="sidebar-info3">
                                        <b><!-- Precio -->{{mobile.rate_price|toFixedDecimals}}€</b>
                                    </span>
                                    </small>
                                </div>

                                <!-- Terminal -->
                                <ng-container *ngIf="mobile.terminal && mobile.terminal != ''">
                                    <div class="resumen d-flex justify-content-between align-items-center">
                                        <!-- terminal -->
                                        <span class="sidebar-info5">
                                            <img src="./assets/img/smartphone2.svg" class="img-fluid" width="13">
                                            {{ mobile.terminal }}
                                        </span>
                                    </div>
                                    <!--<br>-->
                                </ng-container>

                                <div class="resumen d-flexflex-column  justify-content-between align-items-start">
                                    <span class="ml-3 d-block" *ngIf="!mobile?.is_portability && mobile?.msisdn && mobile?.msisdn != ''">
                                        <small>Línea Móvil: <strong>{{ mobile?.msisdn }}</strong></small>
                                    </span>

                                    <span class="ml-3 d-block" *ngIf="mobile?.is_portability && mobile?.msisdn != ''">
                                        <small>Número Portabilidad Móvil: <strong>{{ mobile?.msisdn }}</strong></small>
                                    </span>

                                    <span class="ml-3 d-block" *ngIf="mobile.status != ''">
                                        <small>Estado línea móvil: <strong>{{ mobile.status }}</strong></small>
                                    </span>

                                    <span class="ml-3 d-block" *ngIf="mobile.is_portability && mobile.activation_date != '' && mobile.activation_date != undefined ">
                                        <small>Fecha de portabilidad: <strong>{{ mobile.activation_date }}</strong></small>
                                    </span>

                                    <span class="ml-3 d-block" *ngIf="!mobile.is_portability && mobile.activation_date != '' && mobile.activation_date != undefined ">
                                        <small>Fecha de activación: <strong>{{ mobile.activation_date}}</strong></small>
                                    </span>
                                </div>
                                <hr *ngIf="isLast && order.services.length > 0">
                            </div>

                            <!-- RESUMEN SERVICIOS -->
                            <div *ngFor="let service of order.services; index as i">
                                <hr *ngIf="order.services.length > 1 && i != 0">

                                <div class="resumen d-flex justify-content-between align-items-center mt-2">
                                    <h5 class="tar-name-7 mt-2 text-left">
                                        <b>{{ (i + 1) + 'ª servicio' }}</b>
                                    </h5>
                                </div>

                                <!-- CONCEPTOS -->
                                <div class="resumen d-flex justify-content-between align-items-center">
                                    <span class="sidebar-info4 simyo"> Conceptos</span>
                                    <small class="text-muted">
                                    </small>
                                </div>

                                <div class="resumen d-flex justify-content-between align-items-center">
                                    <!-- tarifa -->
                                    <span class="sidebar-info5">
                                    <img src="./assets/img/package2.svg" class="img-fluid" width="13">
                                    <span> Servicio: {{ service.rate }}</span>
                                </span>
                                    <small class="text-muted" *ngIf="service.rate_price">
                                        <!-- Precio tarifa -->
                                        <span class="sidebar-info3">
                                            <b><!-- Precio -->{{ service.rate_price|toFixedDecimals }}€</b>
                                        </span>
                                    </small>
                                </div>
                                <!-- Terminal -->
                                <ng-container *ngIf="service.terminal && service.terminal != ''">
                                    <div class="resumen d-flex justify-content-between align-items-center">
                                        <!-- terminal -->
                                        <span class="sidebar-info5">
                                            <img src="./assets/img/smartphone2.svg" class="img-fluid" width="13">
                                            {{ service.terminal }}
                                        </span>
                                    </div>
                                    <!--<br>-->
                                </ng-container>

                                <div class="resumen d-flexflex-column  justify-content-between align-items-start">
                                    <span class="ml-3 d-block" *ngIf="service.status != ''">
                                        <small>Estado servicio: <strong>{{ service.status }}</strong></small>
                                    </span>

                                    <span class="ml-3 d-block" *ngIf="service.activation_date != '' && service.activation_date != undefined ">
                                        <small>Fecha de activación: <strong>{{ service.activation_date ?? '-' }}</strong></small>
                                    </span>
                                </div>
                            </div>
                            <!-- <hr> -->
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
