import { ChangeDetectorRef, Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { MyaddressService } from 'src/app/services/myaddress.service';

@Component({
    selector: 'app-simyo-my-address',
    templateUrl: './simyo-my-address.component.html',
    styleUrls: ['./simyo-my-address.component.scss'],
})
export class SimyoMyAddressComponent implements OnInit, OnDestroy {
    @Output() addressSelected = new EventEmitter();

    public showAlertOtherDirection: boolean = false;
    public id: string = '';
    public loading: boolean = false;
    public initialized: boolean = false;
    private subcription: any;

    constructor(@Inject(ChangeDetectorRef) private cdr: ChangeDetectorRef, private myaddressService: MyaddressService) {}

    ngOnInit(): void {
        this.loading = true;
        this.subcription = this.myaddressService.getLoadingMyAddress()
            .subscribe((data: boolean) => {
                this.loading = data;
                this.cdr.detectChanges();
            })
    }

    addressCreated(event) {
        console.log('addressCreated', event);
    }

    sendInitialized(event) {
        this.loading = false;
        this.initialized = true;
        console.log('sendInitialized', event);
    }

    public addrInput(event) {
        const value = (event.target as HTMLInputElement).value;
        if (value === 'Otra dirección') {
            this.showAlertOtherDirection = true;
            this.id = (event.target as HTMLInputElement).id;
        } else {
            this.showAlertOtherDirection = false;
            this.id = '';
        }
    }

    public changeAddress(event) {}

    public getTypeAddress(): string {
        if (this.showAlertOtherDirection) {
            return 'planta';
            if (this.id.toLocaleLowerCase().includes('planta')) {
                return 'planta';
            }
            if (this.id.toLocaleLowerCase().includes('mano2')) {
                return 'puerta';
            }
            if (this.id.toLocaleLowerCase().includes('aclarador')) {
                return 'local empresa';
            }
            if (this.id.toLocaleLowerCase().includes('bis')) {
                return 'bis/duplicado';
            }
            if (this.id.toLocaleLowerCase().includes('bloque')) {
                return 'bloque';
            }
            if (this.id.toLocaleLowerCase().includes('puerta')) {
                return 'portal';
            }
            if (this.id.toLocaleLowerCase().includes('letra')) {
                return 'letra';
            }
            if (this.id.toLocaleLowerCase().includes('escalera')) {
                return 'escalera';
            }
            if (this.id.toLocaleLowerCase().includes('mano1')) {
                return 'tipo puerta';
            }
        }
        return '';
    }

    ngOnDestroy(): void {
        if (this.subcription) {
            this.subcription.unsubscribe();
        }
    }
}
