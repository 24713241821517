import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {NormalizadorService} from '../../services/normalizador.service';
import {SimyoNormalizadorService} from '../../../simyo/services/simyo-normalizador.service';
import {HmCoverageService} from '../../services/hm-coverage.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { availableTechnologies } from 'src/app/shared/constantes';
import { allPermissions } from 'src/app/shared/permissions';
import { Ipdv } from 'src/app/shared/models/pdvResponse';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-hm-cobertura',
    templateUrl: './hm-cobertura.component.html',
    styleUrls: ['./hm-cobertura.component.css', '../../../../assets/css/home-theme.css']
})
export class HmCoberturaComponent implements OnInit, OnDestroy {
    @Input() retryCoverageEvents: Observable<void>;
    @Input() firstFormGroup: FormGroup;
    @Input() pdv: Ipdv;
    @Input() address;

    public muestraCobertura: boolean;
    public muestraErrorPeticion = false;
    public muestraBotonesCobertura = false;
    public muestraBotonNinguna = false;
    public isLoading = false;
    public textDefault = 'Comprobar cobertura';
    // Almacena los datos de la consulta del normalizador
    public datosNormalizacion: any = null;
    // Declaramos el formGroup del componente Cobertura
    public formularioNormalizacion: FormGroup;
    // Datos del modelo
    public localidades: any = null;
    public direccionesSugeridas: any = null;
    public plantas: any = null;
    public bisOption: any = null;
    public bloqueOption: any = null;
    public portalOption: any = null;
    public escaleraOption: any = null;
    public tipoPuertaOption: any = null;
    public puertaOption: any = null;
    public consulta: any = null;
    public msgErrorPeticion: any;
    public orderDraftId = null;
    private allSubscription: Subscription[] = [];
    public environment = environment;

    constructor(
        private formBuilder: FormBuilder,
        private modalService: NgbModal,
        private normalizadorService: NormalizadorService,
        private smNormalizadorService: SimyoNormalizadorService,
        private coverageService: HmCoverageService,
        private permSv: PermissionService,
    ) { }

    ngOnInit(): void {
        // Definimos el formGroup y los formControl que llevara el formulario
        this.firstFormGroup = this.formBuilder.group({
            localidadCobertura: ['', Validators.required],
            direccionesCobertura: ['', Validators.required],
            tipoViaCobertura: ['', ''],
            nombreViaCobertura: ['', ''],
            numeroCobertura: ['', ''],
            provinciaCobertura: ['', ''],
            codigoPostalCobertura: ['', ''],
            plantaCobertura: ['', Validators.required],
            bisCobertura: [null, ''],
            bloqueCobertura: [null, ''],
            portalCobertura: [null, ''],
            escaleraCobertura: [null, ''],
            tipoPuertaCobertura: [null, ''],
            puertaCobertura: [null, ''],
            resultadoConsultaCobertura: ['', ''],
            portabilidadFijo: ['', ''],
            serviceProviderFijo: ['', ''],
            tipoAltaFijo: ['', ''],
            numeroApartamento: ['']
        });

        // Actualizamos el valor de la vista cuando hay cambios
        this.allSubscription.push(this.normalizadorService.getMuestraCobertura()
        .subscribe((value) => {
            this.muestraCobertura = value;

            if(value && this.environment.enableMyAddress) {
                this.muestraBotonesCobertura = true;
            }
       })
    );

        // Recogemos los datos de la normalización cuando hay cambios
        this.allSubscription.push(this.normalizadorService.getDatosNormalizacion()
            .subscribe((valor) => {
                this.datosNormalizacion = valor;
            })
        );

        // Rellenamos las localidades
        this.allSubscription.push(this.normalizadorService.getLocalidades()
            .subscribe(async (local) => {
                this.localidades = local;
                await this.callIfOnlyOneResult();
            })
        );

        // Recuperamos el formulario de normalización
        this.allSubscription.push(this.normalizadorService.getFormularioNormalizacion()
            .subscribe((formulario) => {
                this.formularioNormalizacion = formulario;
            })
        );

        // Errores petición http o direcciones vacias
        this.allSubscription.push(this.normalizadorService.getErrorPeticionCobertura()
            .subscribe((value) => {
                this.muestraErrorPeticion = value;
            })
        );

        this.allSubscription.push(this.normalizadorService.getMsgErrorCobertura()
            .subscribe((value) => {
                this.msgErrorPeticion = value;
            })
        );

        this.allSubscription.push(this.retryCoverageEvents
            .subscribe(() =>
                this.onConsultarCobertura()
            )
        );
    }

    // GETTERS
    callIfOnlyOneResult() {
        if (this.localidades !== null && this.localidades.length === 1) {
            this.firstFormGroup.get('localidadCobertura').patchValue(this.localidades[0]);
            this.onChangeLocalidad(this.localidades[0]);
        }
    }

    // SETTERS
    // Filtramos y seteamos el valor de las direcciones sugeridas
    setDireccionesSugeridas(localidad) {
        const dirSugeridas = this.datosNormalizacion
            .filter((el) => {
                if (el.streetName == null) {
                    return null;
                }
                // return el.city ? el.city.toUpperCase() : el.city === localidad ? localidad.toUpperCase() : localidad;
                return el.city && el.city !== '' ? (el.city.toUpperCase() === localidad.toUpperCase()) : null;
            })
            .map((el) => {
                const valorTexto = (el.streetType ? el.streetType.toUpperCase() + ' , ' : '') +
                    (el.streetName ? el.streetName.toUpperCase() + ' , ' : '') +
                    (el.streetNr ? el.streetNr + ' , ' : '') +
                    (el.postCode ? el.postCode : '');
                return {
                    streetType: el.streetType ? el.streetType.toUpperCase() : el.streetType,
                    streetName: el.streetName ? el.streetName.toUpperCase() : el.streetName,
                    streetNr: el.streetNr,
                    postCode: el.postCode,
                    stateOrProvince: el.stateOrProvince,
                    texto: valorTexto
                };
            });

        this.direccionesSugeridas = this.getUnique(dirSugeridas, 'texto');
        if (this.direccionesSugeridas.length === 1) {
            this.onChangeDireccionesSugeridas(this.direccionesSugeridas[0]);
        }

    }

    setPlantas(dir) {
        const auxPlantas = this.datosNormalizacion
            .filter((el) => {
                return (el.city ? el.city.toUpperCase() : el.city) === (this.firstFormGroup.value.localidadCobertura ? this.firstFormGroup.value.localidadCobertura.toUpperCase() : this.firstFormGroup.value.localidadCobertura) &&
                    (el.streetType ? el.streetType.toUpperCase() : el.streetType) === (dir.streetType ? dir.streetType.toUpperCase() : dir.streetType) &&
                    (el.streetName ? el.streetName.toUpperCase() : el.streetName) === (dir.streetName ? dir.streetName.toUpperCase() : dir.streetName) &&
                    el.streetNr === dir.streetNr &&
                    el.postCode === dir.postCode;
            });
        if (auxPlantas && auxPlantas.length) {
            this.plantas = auxPlantas[0].vertical_addresses
                .map((el) => el.floorNumber_translation)
                .filter((value, index, self) => self.indexOf(value) === index);
        } else {
            this.plantas = null;
        }
    }

    // Asignamos en este punto las direcciones filtradas por si solo queda una (no hay opciones que mostrar)
    setConsulta() {
        const auxConsulta = this.datosNormalizacion
            .filter((el) => {
                return (el.city ? el.city.toUpperCase() : el.city) === (this.firstFormGroup.value.localidadCobertura ? this.firstFormGroup.value.localidadCobertura.toUpperCase() : this.firstFormGroup.value.localidadCobertura) &&
                    (el.streetType ? el.streetType.toUpperCase() : el.streetType) === (this.firstFormGroup.value.tipoViaCobertura ? this.firstFormGroup.value.tipoViaCobertura.toUpperCase() : this.firstFormGroup.value.tipoViaCobertura) &&
                    (el.streetName ? el.streetName.toUpperCase() : el.streetName) === (this.firstFormGroup.value.nombreViaCobertura ? this.firstFormGroup.value.nombreViaCobertura.toUpperCase() : this.firstFormGroup.value.nombreViaCobertura) &&
                    el.streetNr === this.firstFormGroup.value.numeroCobertura &&
                    el.postCode === this.firstFormGroup.value.codigoPostalCobertura;
            });
        if (auxConsulta && auxConsulta.length) {
            this.consulta = auxConsulta[0].vertical_addresses
                .filter((el) => el.floorNumber_translation === this.firstFormGroup.value.plantaCobertura)
        } else {
            this.consulta = null;
        }
    }

    // Consulta sin campo planta obligatorio
    setConsultaNinguna() {
        this.consulta = this.datosNormalizacion
            .filter((el) => {
                return (el.city ? el.city.toUpperCase() : el.city) === (this.firstFormGroup.value.localidadCobertura ? this.firstFormGroup.value.localidadCobertura.toUpperCase() : this.firstFormGroup.value.localidadCobertura) &&
                    (el.streetType ? el.streetType.toUpperCase() : el.streetType) === (this.firstFormGroup.value.tipoViaCobertura ? this.firstFormGroup.value.tipoViaCobertura.toUpperCase() : this.firstFormGroup.value.tipoViaCobertura) &&
                    (el.streetName ? el.streetName.toUpperCase() : el.streetName) === (this.firstFormGroup.value.nombreViaCobertura ? this.firstFormGroup.value.nombreViaCobertura.toUpperCase() : this.firstFormGroup.value.nombreViaCobertura) &&
                    el.streetNr === this.firstFormGroup.value.numeroCobertura &&
                    el.postCode === this.firstFormGroup.value.codigoPostalCobertura;
            });
    }

    // Consultamos si hay opciones para bis duplicado
    setBis(selectedEmpty?) {
        this.bisOption = this.consulta
            // .filter((el) => el.streetNrSuffix_translation)
            .map((el) => el.streetNrSuffix_translation ? el.streetNrSuffix_translation : 'No tiene')
            .filter((value, index, self) => self.indexOf(value) === index);

        if (this.bisOption && this.bisOption.length === 1 && this.bisOption[0] === 'No tiene' && this.firstFormGroup.get('bisCobertura').value !== 'No tiene' && !selectedEmpty) {
            this.bisOption = null;
        }
    }

    // Consultamos si hay opciones para bloque
    setBloque(selectedEmpty?) {
        this.bloqueOption = this.consulta
            // .filter((el) => el.geographicSubAddress[0].subUnitNumber_translation)
            .map((el) => el.geographicSubAddress[0].subUnitNumber_translation ? el.geographicSubAddress[0].subUnitNumber_translation : 'No tiene')
            .filter((value, index, self) => self.indexOf(value) === index);

        if (this.bloqueOption && this.bloqueOption.length === 1 && this.bloqueOption[0] === 'No tiene' && this.firstFormGroup.get('bloqueCobertura').value !== 'No tiene' && !selectedEmpty) {
            this.bloqueOption = null;
        }
    }

    // Consultamos si hay opciones para portal
    setPortal(selectedEmpty?) {
        this.portalOption = this.consulta
            // .filter((el) => el.geographicSubAddress[0].buildingName)
            .map((el) => el.geographicSubAddress[0].buildingName ? el.geographicSubAddress[0].buildingName : 'No tiene')
            .filter((value, index, self) => self.indexOf(value) === index);

        if (this.portalOption && this.portalOption.length === 1 && this.portalOption[0] === 'No tiene' && this.firstFormGroup.get('portalCobertura').value !== 'No tiene' && !selectedEmpty) {
            this.portalOption = null;
        }
    }

    // Consultamos si hay opciones para escalera
    setEscalera(selectedEmpty?) {
        this.escaleraOption = this.consulta
            // .filter((el) => el.geographicSubAddress[0].stairCase_translation)
            .map((el) => el.geographicSubAddress[0].stairCase_translation ? el.geographicSubAddress[0].stairCase_translation : 'No tiene')
            .filter((value, index, self) => self.indexOf(value) === index);

        if (this.escaleraOption && this.escaleraOption.length === 1 && this.escaleraOption[0] === 'No tiene' && this.firstFormGroup.get('escaleraCobertura').value !== 'No tiene' && !selectedEmpty) {
            this.escaleraOption = null;
        }
    }

    // Consultamos si hay opciones para tipo puerta
    setTipoPuerta(selectedEmpty?) {
        this.tipoPuertaOption = this.consulta
            // .filter((el) => el.geographicSubAddress[0].doorType_translation)
            .map((el) => el.geographicSubAddress[0].doorType_translation ? el.geographicSubAddress[0].doorType_translation : 'No tiene')
            .filter((value, index, self) => self.indexOf(value) === index);

        if (this.tipoPuertaOption && this.tipoPuertaOption.length === 1 && this.tipoPuertaOption[0] === 'No tiene' && this.firstFormGroup.get('tipoPuertaCobertura').value !== 'No tiene' && !selectedEmpty) {
            this.tipoPuertaOption = null;
        }
    }

    // Consultamos si hay opciones para puerta
    setPuerta(selectedEmpty?) {
        this.puertaOption = this.consulta
            // .filter((el) => el.geographicSubAddress[0].door)
            .map((el) => el.geographicSubAddress[0].door ? el.geographicSubAddress[0].door : 'No tiene')
            .filter((value, index, self) => self.indexOf(value) === index);

        if (this.puertaOption && this.puertaOption.length === 1 && this.puertaOption[0] === 'No tiene' && this.firstFormGroup.get('puertaCobertura').value !== 'No tiene' && !selectedEmpty) {
            this.puertaOption = null;
        }
    }

    // METODOS ON
    onChangeLocalidad(localidad) {
        // Limpiamos todas las variables para que afecte a la vista
        this.firstFormGroup.patchValue({
            direccionesCobertura: '',
            tipoViaCobertura: '',
            nombreViaCobertura: '',
            numeroCobertura: '',
            codigoPostalCobertura: '',
            provinciaCobertura: '',
            plantaCobertura: '',
            bisCobertura: null,
            bloqueCobertura: null,
            portalCobertura: null,
            escaleraCobertura: null,
            tipoPuertaCobertura: null,
            puertaCobertura: null
        });
        this.direccionesSugeridas = null;
        this.plantas = null;
        this.bisOption = null;
        this.bloqueOption = null;
        this.portalOption = null;
        this.escaleraOption = null;
        this.tipoPuertaOption = null;
        this.puertaOption = null;
        // Ocultamos botones de consulta
        this.muestraBotonesCobertura = false;
        this.muestraBotonNinguna = false;
        this.firstFormGroup.get('localidadCobertura').patchValue(localidad);
        this.setDireccionesSugeridas(localidad);
    }

    onChangeDireccionesSugeridas(dir) {
        this.firstFormGroup.patchValue({
            plantaCobertura: '',
            bisCobertura: null,
            bloqueCobertura: null,
            portalCobertura: null,
            escaleraCobertura: null,
            tipoPuertaCobertura: null,
            puertaCobertura: null
        });
        this.plantas = null;
        this.bisOption = null;
        this.bloqueOption = null;
        this.portalOption = null;
        this.escaleraOption = null;
        this.tipoPuertaOption = null;
        this.puertaOption = null;
        // Mostramos boton ninguna anteriores
        this.muestraBotonNinguna = true;
        this.muestraBotonesCobertura = false;
        // Asignamos los valores al firstFormGroup para que sea accesible desde todo el formulario
        this.firstFormGroup.get('direccionesCobertura').patchValue(dir.texto ? dir.texto : (dir.streetName ? dir.streetName.toUpperCase() : dir.streetName));
        this.firstFormGroup.get('tipoViaCobertura').patchValue(dir.streetType);
        this.firstFormGroup.get('nombreViaCobertura').patchValue(dir.streetName ? dir.streetName.toUpperCase() : dir.streetName);
        this.firstFormGroup.get('numeroCobertura').patchValue(dir.streetNr);
        this.firstFormGroup.get('codigoPostalCobertura').patchValue(dir.postCode);
        this.firstFormGroup.get('provinciaCobertura').patchValue(dir.stateOrProvince ? dir.stateOrProvince.toUpperCase() : dir.stateOrProvince);

        this.setPlantas(dir);
    }

    onChangePlanta() {
        this.resetControlsForm();
        // Limpiamos todas las variables para que afecte a la vista
        this.firstFormGroup.patchValue({
            bisCobertura: null,
            bloqueCobertura: null,
            portalCobertura: null,
            escaleraCobertura: null,
            tipoPuertaCobertura: null,
            puertaCobertura: null
        });
        this.bisOption = null;
        this.bloqueOption = null;
        this.portalOption = null;
        this.escaleraOption = null;
        this.tipoPuertaOption = null;
        this.puertaOption = null;
        // Mostramos botones de consulta
        this.muestraBotonesCobertura = true;
        // Rellenamos las opciones si hay resultado para ellas
        this.setConsulta();
        this.setBis();
        this.setBloque();
        this.setPortal();
        this.setEscalera();
        this.setTipoPuerta();
        this.setPuerta();

    }

    // Retroalimentamos la consulta para que solo nos muestre las opciones correctas para lo seleccionado
    onSelectOpcion(key, value) {
        let selectedEmptyMain = false;
        let selectedEmpty = {
            bis: false,
            bloque: false,
            portal: false,
            escalera: false,
            tipoPuerta: false,
            puerta: false
        };
        if (value === '') {
            return;
        }
        if (value === 'No tiene') {
            value = null;
            selectedEmptyMain = true;
        }
        // bis
        if (key === 'streetNrSuffix') {
            this.consulta = this.consulta
                .filter((el) => el[key + '_translation'] === value)
                .map((el) => el);

            selectedEmpty.bis = selectedEmptyMain;
        }
        // Bloque
        if (key === 'subUnitNumber') {
            this.consulta = this.consulta
                .filter((el) => el.geographicSubAddress[0][key + '_translation'] === value)
                .map((el) => el);

            selectedEmpty.bloque = selectedEmptyMain;
        }
        // Portal
        if (key === 'buildingName') {
            this.consulta = this.consulta
                .filter((el) => el.geographicSubAddress[0][key] === value)
                .map((el) => el);

            selectedEmpty.portal = selectedEmptyMain;
        }
        // Escalera
        if (key === 'stairCase') {
            this.consulta = this.consulta
                .filter((el) => el.geographicSubAddress[0][key + '_translation'] === value)
                .map((el) => el);

            selectedEmpty.escalera = selectedEmptyMain;
        }
        // Tipo puerta
        if (key === 'doorType') {
            this.consulta = this.consulta
                .filter((el) => el.geographicSubAddress[0][key + '_translation'] === value)
                .map((el) => el);

            selectedEmpty.tipoPuerta = selectedEmptyMain;
        }
        // Puerta
        if (key === 'door') {
            this.consulta = this.consulta
                .filter((el) => el.geographicSubAddress[0][key] === value)
                .map((el) => el);

            selectedEmpty.puerta = selectedEmptyMain;
        }
        this.setBis(selectedEmpty.bis);
        this.setBloque(selectedEmpty.bloque);
        this.setPortal(selectedEmpty.portal);
        this.setEscalera(selectedEmpty.escalera);
        this.setTipoPuerta(selectedEmpty.tipoPuerta);
        this.setPuerta(selectedEmpty.puerta);
        this.addAllValidationsCobertura();
    }

    onConsultarCobertura() {
        let currentZip = this.firstFormGroup.get('codigoPostalCobertura').value;

        if (this.formularioNormalizacion != null && (currentZip === null || currentZip === '')) {
            currentZip = this.formularioNormalizacion.get('codigoPostalNormaliza').value;
            this.firstFormGroup.get('codigoPostalCobertura').patchValue(currentZip);
        }

        this.normalizadorService.setErrorPeticionCobertura(false);
        this.normalizadorService.setMsgErrorCobertura('');
        this.addAllValidationsCobertura();
        // this.compruebaSiExisteConsulta();
        this.validateAllFormFields(this.firstFormGroup);

        if(environment.enableMyAddress) {
            this.setDataMyAddress();
        }

        if (this.firstFormGroup.status === 'VALID') {
            this.coverageService.setSmCoverage(null);
            this.onChangeLoading(true);
            let body;

            if(environment.enableMyAddress) {
                body = this.getBodyMyaddress()
            } else {
                if (this.consulta.length > 1) {
                    const found = this.consulta.find(x => x.geographicSubAddress && x.geographicSubAddress.length && x.geographicSubAddress[0].subUnitNumber_translation === this.firstFormGroup.get('bloqueCobertura').value);
                    body = found ? found : this.consulta[0];
                } else {
                    body = this.consulta[0];
                }
            }

            this.normalizadorService.setResultadoCobertura(this.firstFormGroup);
            this.normalizadorService.setMuestraNormalizacion(false);
            this.normalizadorService.setMuestraCobertura(false);

            this.consultarCoberturaSimyo(body);
        } else {
            this.onChangeLoading(false);
        }
    }

    setDataMyAddress() {
        const formDetail = this.address?.detail;

        if (!formDetail?.ospGeographicAddress?.length) {
            return;
        }
        this.firstFormGroup.patchValue({
            localidadCobertura: formDetail.ospGeographicAddress[0].locality ?? formDetail.ospGeographicAddress[0]?.city,
            direccionesCobertura: `${formDetail?.ospGeographicAddress[0]?.streetType}, ${formDetail?.ospGeographicAddress[0]?.streetName}, ${formDetail?.ospGeographicAddress[0]?.streetNr}, ${formDetail?.ospGeographicAddress[0]?.postCode}`,
            tipoViaCobertura: formDetail.ospGeographicAddress[0].streetType,
            nombreViaCobertura: formDetail.ospGeographicAddress[0].streetName,
            numeroCobertura: formDetail.ospGeographicAddress[0].streetNr,
            provinciaCobertura: formDetail.ospGeographicAddress[0].stateOrProvince,
            codigoPostalCobertura: formDetail.ospGeographicAddress[0].postCode,
            plantaCobertura: formDetail.ospGeographicAddress[0].geographicSubAddress[0].levelNumber,
            bisCobertura: formDetail.ospGeographicAddressTR.bis_TR,
            bloqueCobertura: formDetail.ospGeographicAddressTR.bloque_TR,
            portalCobertura: formDetail.ospGeographicAddress[0].streetNr,
            escaleraCobertura: formDetail.ospGeographicAddress[0].geographicSubAddress[0].stairCase,
            tipoPuertaCobertura: formDetail.ospGeographicAddress[0].geographicSubAddress[0].doorType,
            puertaCobertura: formDetail.ospGeographicAddress[0].geographicSubAddress[0].door,
            numeroApartamento: formDetail.ospGeographicAddress[0].geographicSubAddress[0].door
        });
    }

    getBodyMyaddress() {
        const formDetail = this.address?.detail;

        return {
            "id": null,
            "href": null,
            "ospAddressExternalId": [{ 
                    "refId": "arvato", 
                    "externalId": formDetail.ospGeographicAddress[0].externalIdentifier.find(item => item.name === 'ospAddressId')?.value
                },
                {
                    "refId": "gescal",
                    "externalId": formDetail.ospGeographicAddress[0].externalIdentifier.find(item => item.name === 'gescal37')?.value
                }
            ],
            // formDetail.ospGeographicAddress[0].externalIdentifier,
            "ospAccuracy": null,
            "streetNr": formDetail.ospGeographicAddress[0].streetNr,
            "streetNrSuffix": formDetail.ospGeographicAddress[0].streetNrSuffix,
            "streetNrLast": formDetail.ospGeographicAddress[0].streetNrLast,
            "streetNrLastSuffix": formDetail.ospGeographicAddress[0].streetNrLastSuffix,
            "streetName": formDetail.ospGeographicAddress[0].streetName,
            "streetType": formDetail.ospGeographicAddress[0].streetType,
            "postCode": formDetail.ospGeographicAddress[0].postCode,
            "locality": formDetail.ospGeographicAddress[0].locality,
            "city": formDetail.ospGeographicAddress[0].city,
            "ospINECityCode": formDetail.ospGeographicAddress[0].externalIdentifier.find(item => item.name === 'ospINECityCode')?.value,
            "ospSingularEntity": formDetail.ospGeographicAddress[0].externalIdentifier.find(item => item.name === 'ospINESingularEntity')?.value,
            "stateOrProvince": formDetail.ospGeographicAddress[0].stateOrProvince,
            "country": "SPAIN",
            "staircaseNumber": formDetail.ospGeographicAddress[0].geographicSubAddress[0].stairCase,
            "floorNumber": formDetail.ospGeographicAddress[0].geographicSubAddress[0].levelNumber,
            "apartmentNumber": null,
            "geocode": null,
            "geographicSubAddress": formDetail.ospGeographicAddress[0].geographicSubAddress,
            "streetNrSuffix_translation": null,
            // "floorNumber_translation": "Planta 1"
        }
    }

    consultarCoberturaSimyo(body) {
        if (this.permSv.hasPermFromV2(allPermissions.simyo.permite_ventas_nuevo_frontal) && this.permSv.hasPermFromV2(allPermissions.simyo.permite_vender_broadband)) {
            this.smNormalizadorService.consultaCobertura(body).subscribe((data: any) => {
                if (data.msg) {
                    data = data.msg;
                }
                this.coverageService.setSmErrorCoverage(false);
                if (data.error?.errorCode === 500) {
                    this.errorCoberturaSimyo(data.error?.msg);
                } else {
                    if (data.cimaArvatoCode && data.cimaArvatoCode !== '' && availableTechnologies.includes(data.technology[0])) {
                        this.firstFormGroup.get('resultadoConsultaCobertura').patchValue(data);
                        this.clearFormGroup();
                        this.smNormalizadorService.setResultadoCobertura(this.firstFormGroup);
                        this.coverageService.setSmCoverage(true);
                    } else {
                        this.coverageService.setSmCoverage(false);
                    }
                }
                this.onChangeLoading(false);
            }, (error) => {
                this.errorCoberturaSimyo('Ha ocurrido un error al realizar la consulta de cobertura. Por favor inténtalo de nuevo.');
            });
        } else {
            this.coverageService.setSmCoverage(false);
        }
    }

    errorCoberturaSimyo(msg) {
        this.onChangeLoading(false);
        this.firstFormGroup.enable();
        this.coverageService.setSmCoverage(false);
        this.coverageService.setSmErrorCoverage(msg);
    }

    onConsultarNingunaAnteriores() {
        this.modalService.dismissAll();

        this.normalizadorService.setErrorPeticionCobertura(false);
        this.normalizadorService.setMsgErrorCobertura('');
        this.resetControlsForm();
        this.clearValidations('plantaCobertura');
        this.clearValidations('bisCobertura');
        this.clearValidations('bloqueCobertura');
        this.clearValidations('portalCobertura');
        this.clearValidations('escaleraCobertura');
        this.clearValidations('tipoPuertaCobertura');
        this.clearValidations('puertaCobertura');
        if (this.firstFormGroup.status === 'VALID') {
            this.onChangeLoading(true);
            this.firstFormGroup.disable();
            this.setConsultaNinguna();
            this.firstFormGroup.patchValue({
                bisCobertura: null,
                bloqueCobertura: null,
                portalCobertura: null,
                escaleraCobertura: null,
                tipoPuertaCobertura: null,
                puertaCobertura: null,
                plantaCobertura: null
            });
            const body = this.limpiarConsultaNingunaAnteriores(this.consulta);

            this.normalizadorService.setResultadoCobertura(this.firstFormGroup);
            this.normalizadorService.setMuestraNormalizacion(false);
            this.normalizadorService.setMuestraCobertura(false);

            this.consultarCoberturaNingunaAnterioresSimyo(body);
        } else {
            this.onChangeLoading(false);
            this.firstFormGroup.enable();
        }
    }

    consultarCoberturaNingunaAnterioresSimyo(body) {
        if (this.permSv.hasPermFromV2(allPermissions.simyo.permite_ventas_nuevo_frontal) && this.permSv.hasPermFromV2(allPermissions.simyo.permite_vender_broadband)) {
            this.smNormalizadorService.consultaCobertura(body).subscribe((data: any) => {
                if (data.msg) {
                    data = data.msg;
                }

                if (data.cimaArvatoCode && data.cimaArvatoCode !== '' && availableTechnologies.includes(data.technology[0])) {
                    this.firstFormGroup.get('resultadoConsultaCobertura').patchValue(data);
                    this.smNormalizadorService.setResultadoCobertura(this.firstFormGroup);
                    this.coverageService.setSmCoverage(true);
                } else {
                    this.coverageService.setSmCoverage(false);
                }

                this.onChangeLoading(false);
            }, (error) => {
                this.onChangeLoading(false);
                this.firstFormGroup.enable();
                this.coverageService.setSmCoverage(false);
            });
        } else {
            this.coverageService.setSmCoverage(false);
        }
    }

    limpiarConsultaNingunaAnteriores(consultaInicial) {
        if (consultaInicial && consultaInicial.length) {
            let consulta = '';
            consultaInicial[0].geographicSubAddress[0].levelNumber = null;
            consultaInicial[0].streetNrSuffix = null;
            consultaInicial[0].geographicSubAddress[0].subUnitType = null;
            consultaInicial[0].geographicSubAddress[0].subUnitNumber = null;
            consultaInicial[0].geographicSubAddress[0].buildingName = null;
            consultaInicial[0].geographicSubAddress[0].stairCase = null;
            consultaInicial[0].geographicSubAddress[0].doorType = null;
            consultaInicial[0].apartmentNumber = null;
            consultaInicial[0].geographicSubAddress[0].door = null;
            consultaInicial[0].staircaseNumber = null;
            consultaInicial[0].streetNrLast = null;
            consultaInicial[0].streetNrLastSuffix = null;
            consultaInicial[0].streetNrSuffix = null;
            // Si existe código gescal lo limpiamos
            for (let i = 0; i < consultaInicial[0].ospAddressExternalId.length; i++) {
                if (consultaInicial[0].ospAddressExternalId[i].refId !== null) {
                    if (consultaInicial[0].ospAddressExternalId[i].refId === 'gescal') {
                        consultaInicial[0].ospAddressExternalId[i].externalId = null;
                    }
                }
            }
            consulta = consultaInicial[0];

            return consulta;
        }
    }

    // Restablece el formulario a la configuración inicial
    onReset(formCobertura) {
        this.firstFormGroup.reset();
        this.formularioNormalizacion.enable();
        this.formularioNormalizacion.reset();
        this.localidades = null;
        this.direccionesSugeridas = null;
        this.plantas = null;
        this.bisOption = null;
        this.bloqueOption = null;
        this.portalOption = null;
        this.escaleraOption = null;
        this.tipoPuertaOption = null;
        this.puertaOption = null;
        this.normalizadorService.resetNormalizacion();
        this.normalizadorService.setLocalidades(null);
        this.normalizadorService.setErrorPeticionCobertura(false);
        this.normalizadorService.setMsgErrorCobertura('');

        this.resetControlsForm();

    }

    // VALIDACIONES

    // Añade validación al elemento enviado, solo si hay resultados para ese elemento
    addRequiredControl(nombreControl, elemento) {
        if (elemento) {
            const identificador = this.firstFormGroup.get(nombreControl);
            if (elemento.length) {
                identificador.setValidators([Validators.required]);
            } else {
                this.clearValidations(nombreControl);
            }
        } else {
            this.clearValidations(nombreControl);
        }
    }

    // Añade validaciones para todo el formulario de cobertura
    addAllValidationsCobertura() {
        this.addRequiredControl('bisCobertura', this.bisOption);
        this.addRequiredControl('bloqueCobertura', this.bloqueOption);
        this.addRequiredControl('portalCobertura', this.portalOption);
        this.addRequiredControl('escaleraCobertura', this.escaleraOption);
        this.addRequiredControl('tipoPuertaCobertura', this.tipoPuertaOption);
        this.addRequiredControl('puertaCobertura', this.puertaOption);
    }

    // Reset errores de validación para la consulta ninguna de las anteriores
    resetControlsForm() {
        this.firstFormGroup.get('bisCobertura').clearValidators();
        this.firstFormGroup.get('bloqueCobertura').clearValidators();
        this.firstFormGroup.get('portalCobertura').clearValidators();
        this.firstFormGroup.get('escaleraCobertura').clearValidators();
        this.firstFormGroup.get('tipoPuertaCobertura').clearValidators();
        this.firstFormGroup.get('puertaCobertura').clearValidators();
    }

    // Comprueba si existen errores de validacion y hace saltar estos errores
    validateAllFormFields(formGroup: FormGroup | FormArray): boolean {
        const keysArray = formGroup instanceof FormGroup ? Object.values(formGroup.controls) : formGroup.controls;
        keysArray.forEach(control => {
            if (control instanceof FormControl) {
                control.markAsTouched({onlySelf: false});
                control.updateValueAndValidity();
            } else if (control instanceof FormGroup || control instanceof FormArray) {
                this.validateAllFormFields(control);
            }
        });
        return formGroup.invalid;
    }

    // Funcion para limpiar las validaciones de un campo del formulario
    clearValidations(nameForm: string) {
        this.firstFormGroup.get(nameForm).clearValidators();
        this.firstFormGroup.get(nameForm).setValidators([]);
        this.firstFormGroup.get(nameForm).updateValueAndValidity();
    }

    // Función para manejar los mensajes de error
    public errorHandling = (control: string, error: string) => {
        if (this.firstFormGroup.get(control).invalid && (this.firstFormGroup.get(control).dirty || this.firstFormGroup.get(control).touched)) {
            return this.firstFormGroup.controls[control].hasError(error);
        }
    }

    // UTILS

    // Devuelve valores unicos para un identificador dado
    getUnique(array, identificador) {
        const unique = array
            .map(e => e[identificador])
            .map((e, i, final) => final.indexOf(e) === i && i)
            .filter(e => array[e]).map(e => array[e]);

        return unique;
    }

    onChangeLoading(state) {
        if (state === true) {
            this.isLoading = true;
            this.textDefault = 'Comprobando ...';
        } else {
            this.isLoading = false;
            this.textDefault = 'Comprobar cobertura';
        }
    }

    clearFormGroup() {
        for (const field in this.firstFormGroup.controls) {
            if (this.firstFormGroup.get(field).value === 'No tiene') {
                this.firstFormGroup.get(field).patchValue(null);
            }
        }
    }

    ngOnDestroy() {
        if (this.allSubscription && this.allSubscription.length) {
            this.allSubscription.map(subs => subs.unsubscribe());
        }
    }
}
