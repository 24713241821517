<myaddress-component
    showMap="false"
    origin="DIGO"
    environment="PRE"
    coverage="false"
    hiddenVertical="true"
    (input)="addrInput($event)"
    (addrselected)="addressSelected.emit($event)"
    (addrcreated)="addressCreated($event)"
    (sendInitialized)="sendInitialized($event)"
    (change)="changeAddress($event)"
></myaddress-component>

<div *ngIf="loading" class="loading" align="center" [ngStyle]="{'position': (initialized ? 'absolute' : 'relative')}">
    <img class="" src="./assets/img/spinner-home.svg" width="50" alt="">
</div>

<!-- <div *ngIf="showAlertOtherDirection" style="padding: 10px; border: 2px red solid">
    <b>Hemos ampliado tu búsqueda. Por favor, selecciona de nuevo la {{ getTypeAddress() }} de tu vivienda para continuar con el proceso</b>
</div> -->
