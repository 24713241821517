<section class="procesos mt-3 mb-5">
    <div class="row">
        <div class="col-lg-12">
            <h6 class="cobertura--title font-weight-bold">
                <i-feather name="map-pin" style="width: 12px;"></i-feather>
                Dirección de instalación
            </h6>

            <hr>
        </div>
    </div>

    <div class="row" [hidden]="!muestraNormalizacion">
        <div class="col-lg-12 mt-2">
            <div class="jumbotron-norm mb-3">
                <ng-container *ngIf="enviroment.enableMyAddress">
                    <div class="form-row normalizador">
                        <div class="col-12 mb-0">
                            <app-simyo-my-address (addressSelected)="myAddressSelected($event)"></app-simyo-my-address>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="!enviroment.enableMyAddress">
                    <div class="form-row normalizador">
                        <div class="col-lg-10 mb-0">
                            <mat-form-field [formGroup]="formGroup">
                                <mat-label>Nombre de la vía</mat-label>
                                <input type="text" matInput matGoogleMapsAutocomplete name="nombreViaNormaliza"
                                    formControlName="nombreViaNormaliza" autocomplete="nope" placeholder=""
                                    [types]="['address']" [country]="country"
                                    (input)="onAddressChange($event);sendEvent($event);formGroup.get('tipoViaNormaliza').patchValue('');"
                                    (focusin)="onAddressChange($event)" (focusout)="onAddressFocusOut()"
                                    (onAutocompleteSelected)="onAutocompleteSelected($event)" />
                                <!-- error -->
                                <mat-error *ngIf="errorHandling('nombreViaNormaliza', 'required')">
                                    Campo obligatorio.
                                </mat-error>
                            </mat-form-field>

                            <div #manualAddress class="manual-address"
                                (click)="formGroup.get('tipoViaNormaliza').patchValue('');formNumber.focus()">
                                <strong #manualAddressText class="manual-address-text"></strong>
                                <span class="manual-address-link">Continuar con selección manual</span>
                            </div>
                        </div>
                        <div class="col-lg-2 mb-0">
                            <mat-form-field [formGroup]="formGroup">
                                <mat-label>Número</mat-label>
                                <input #formNumber matInput type="number" name="numeroNormaliza"
                                    formControlName="numeroNormaliza" pattern="\d+" min="0" autocomplete="nope">
                                <!-- error -->
                                <mat-error *ngIf="errorHandling('numeroNormaliza', 'required')">
                                    Campo obligatorio.
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <!--SEGUNDA FILA-->
                    <div class="form-row normalizador">
                        <div class="col-lg-3 mb-1">
                            <mat-form-field [formGroup]="formGroup">
                                <mat-label>Código Postal</mat-label>
                                <input matInput type="number" name="codigoPostalNormaliza"
                                    formControlName="codigoPostalNormaliza" autocomplete="nope"
                                    (input)="onChangeZip($event.target.value);" pattern="\d+" min="0" max="99999">
                                <!-- error -->
                                <mat-error *ngIf="errorHandling('codigoPostalNormaliza', 'required')">
                                    Campo obligatorio.
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-5 mb-1">
                            <mat-form-field [formGroup]="formGroup">
                                <mat-label>Localidad</mat-label>
                                <input matInput type="text" name="localidadNormaliza"
                                    formControlName="localidadNormaliza" autocomplete="nope">
                                <!-- error -->
                                <mat-error *ngIf="errorHandling('localidadNormaliza', 'required')">
                                    Campo obligatorio.
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 mb-1">
                            <mat-form-field [formGroup]="formGroup">
                                <mat-label>Provincia</mat-label>
                                <select matNativeControl formControlName="provinciaNormaliza">
                                    <option *ngFor="let datos of datosProvincia" value="{{datos.code}}">
                                        {{datos.code}}
                                    </option>
                                </select>
                                <!-- error -->
                                <mat-error *ngIf="errorHandling('provinciaNormaliza', 'required')">
                                    Campo obligatorio.
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <mat-error *ngIf="muestraErrorPeticion == true">
                        <p class="alert alert-warning">
                            {{msgErrorPeticion}}
                        </p>
                    </mat-error>

                    <div class="row" *ngIf="muestraBoton == true">
                        <div class="col-12 col-sm">
                            <button id="submit" type="submit" class="btn btn-black-outline mt-3 mb-1 spinner"
                                [disabled]="isLoading" (click)="onNormalizarDireccion()">
                                <span *ngIf="isLoading" class="spinner-grow spinner-grow-sm" role="status"
                                    aria-hidden="true"></span>
                                {{textDefault}}
                            </button>
                        </div>

                        <div class="col-12 col-sm-auto py-3 py-sm-0 align-self-center">
                            <span (click)="onEdit(true)" class="text-direccion">Limpiar formulario</span>
                        </div>
                    </div>
                </ng-container>

                <app-hm-cobertura [retryCoverageEvents]="retryCoverageSubject.asObservable()" [pdv]="pdv" [address]="myaddress"></app-hm-cobertura>
            </div>
        </div>
    </div>

    <div class="row" *ngIf="!muestraNormalizacion && !muestraCobertura">
        <div class="col-lg-12 mt-2">
            <div class="animated fadeIn">
                <!----- Dirección de la normalización ------->
                <div class="jumbotron-dir">
                    <div class="d-flex justify-content-between align-items-center">
                        <div>
                            <!----- Dirección ------->
                            <span class="dir-text">
                                {{ resultadoCobertura.get('tipoViaCobertura').value }} {{
                                resultadoCobertura.get('nombreViaCobertura').value }}
                                <span
                                    *ngIf="resultadoCobertura.get('numeroCobertura').value && resultadoCobertura.get('numeroCobertura').value !== 'No tiene'">
                                    Número {{ resultadoCobertura.get('numeroCobertura').value }}
                                </span>
                                <span
                                    *ngIf="resultadoCobertura.get('plantaCobertura').value && resultadoCobertura.get('plantaCobertura').value !== 'No tiene'">
                                    {{ includeFloor(resultadoCobertura.get('plantaCobertura').value) }} {{
                                    isNumber(resultadoCobertura.get('plantaCobertura').value) }}
                                </span>
                                <span
                                    *ngIf="resultadoCobertura.get('portalCobertura').value && resultadoCobertura.get('portalCobertura').value !== 'No tiene'">
                                    Portal {{ resultadoCobertura.get('portalCobertura').value }}
                                </span>
                                <span
                                    *ngIf="resultadoCobertura.get('bisCobertura').value && resultadoCobertura.get('bisCobertura').value !== 'No tiene'">
                                    Bis {{ resultadoCobertura.get('bisCobertura').value }}
                                </span>
                                <span
                                    *ngIf="resultadoCobertura.get('bloqueCobertura').value && resultadoCobertura.get('bloqueCobertura').value !== 'No tiene'">
                                    Bloque {{ resultadoCobertura.get('bloqueCobertura').value }}
                                </span>
                                <span
                                    *ngIf="resultadoCobertura.get('escaleraCobertura').value && resultadoCobertura.get('escaleraCobertura').value !== 'No tiene'">
                                    Escalera {{ resultadoCobertura.get('escaleraCobertura').value }}
                                </span>
                                <span
                                    *ngIf="resultadoCobertura.get('tipoPuertaCobertura').value && resultadoCobertura.get('tipoPuertaCobertura').value !== 'No tiene'">
                                    Tipo puerta {{ resultadoCobertura.get('tipoPuertaCobertura').value }}
                                </span>
                                <span
                                    *ngIf="resultadoCobertura.get('puertaCobertura').value && resultadoCobertura.get('puertaCobertura').value !== 'No tiene'">
                                    Puerta {{ resultadoCobertura.get('puertaCobertura').value }}
                                </span>
                            </span>
                            <br>
                            <!----- CP / Ciudad ------->
                            <span class="dir-text2">
                                {{ resultadoCobertura.get('codigoPostalCobertura').value }} {{
                                resultadoCobertura.get('localidadCobertura').value }}
                            </span>
                            <br>
                            <!----- Provincia ------->
                            <span class="dir-text2">
                                {{ resultadoCobertura.get('provinciaCobertura').value }}
                            </span>
                        </div>
                        <small class="text-muted">
                            <a class="btn btn-black-sidebar1 btn-sm" (click)="onEdit()" role="button">Cambiar
                                dirección</a>
                        </small>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="!muestraNormalizacion && !muestraCobertura && (smCoverage == null)">
        <h5 align="center" class="tar-name-preload">Consultando cobertura</h5>
        <div align="center">
            <img class="" src="./assets/img/spinner-home.svg" width="30" alt="">
        </div>
    </div>

    <div class="row" *ngIf="smCoverage != null">
        <div class="col-lg-12 mt-2">
            <div class="animated fadeIn">
                <h6 class="resultados--title">
                    Marcas con cobertura
                </h6>

                <hr class="mb-4">

                <div class="row">
                    <!-- Simyo -->
                    <!-- Tiene cobertura con Simyo -->
                    <div class="col-xl-4" *ngIf="smCoverage">
                        <div class="card--tarifas--digo mb-3">
                            <div class="card-header--digo">
                                <div class="row">
                                    <div class="col-12 col-xl">
                                        <img src="./assets/img/sm-login-color.svg" class="img-fluid mb-0 logo--tarifa"
                                            width="140">
                                    </div>
                                    <div class="col-12 col-xl-auto">
                                        <h6 class="ml-2 marca--sub pt-2">
                                            <span class="font-weight-bold"><span class="dot--ok"></span> Tienes
                                                cobertura</span><br>
                                        </h6>
                                    </div>
                                </div>
                            </div>

                            <div class="card-body--digo border-bottom">
                                <!-- Oferta -->
                                <h6 class="ml-1 marca--sub">Tipo de cobertura:</h6>

                                <!-- Button group -->
                                <div align="center" class="btn-group mt-2">
                                    <div class="btn btn-gray--round dark btn-sm">
                                        {{
                                        fiberIndirectTechnologies.includes(smResultadoCobertura?.getRawValue()?.resultadoConsultaCobertura?.technology.length
                                        ? smResultadoCobertura?.getRawValue()?.resultadoConsultaCobertura?.technology[0]
                                        : '') ? 'Fibra indirecta' : 'Fibra directa' }}
                                    </div>
                                </div>
                            </div>

                            <div class="card-body--digo"
                                *ngIf="permSv.hasPermFromV2(allPermissions.others.access_night)">
                                <!-- Oferta -->
                                <h6 class="ml-1 marca--sub">Ofertas para el cliente en:</h6>

                                <!-- Button group -->
                                <div align="center" class="btn-group mt-2 mb-3">
                                    <button (click)="goToSimyo('convergent')"
                                        class="btn btn-black--outline btn-sm ml-1">Fibra y móvil</button>
                                    <button (click)="goToSimyo('broadband')"
                                        class="btn btn-black--outline btn-sm ml-2">Fibra</button>
                                </div>
                            </div>

                            <div style="border-bottom: 5px solid #FF6C01;"></div>
                        </div>
                    </div>

                    <!-- Error petición cobertura Simyo -->
                    <div class="col-xl-4" *ngIf="!smCoverage && (smErrorCoverage && smErrorCoverage !== '')">
                        <div class="card--tarifas--digo mb-3">
                            <div class="card-header--digo">
                                <div class="row">
                                    <div class="col-12 col-xl">
                                        <img src="./assets/img/sm-login-color.svg" class="img-fluid mb-0 logo--tarifa"
                                            width="140">
                                    </div>
                                    <div class="col-12 col-xl-auto">
                                        <h6 class="ml-2 marca--sub pt-3" *ngIf="!smCoverage">
                                            <span class="font-weight-bold">
                                                {{ smErrorCoverage }}
                                            </span>
                                            <!-- Button group -->
                                            <div align="center" class="btn-group mt-3">
                                                <button (click)="retryCheckCoverage()"
                                                    class="btn btn-black--outline btn-sm">Comprobar cobertura</button>
                                            </div>
                                            <br>
                                        </h6>
                                    </div>
                                </div>
                            </div>

                            <div style="border-bottom: 5px solid #FF6C01;"></div>
                        </div>
                    </div>
                    <!-- Simyo -->

                </div>
            </div>
        </div>

        <div id="no-coverage" class="col-lg-12 row mt-2" *ngIf="!smCoverage && !smErrorCoverage && !permSv.hasPermFromV2(allPermissions.jazztel.permite_ventas)">
            <div class="col my-3 text-center">
                <h5 class="mb-0">Lo sentimos, no tienes cobertura</h5>
                en la dirección indicada.
            </div>
        </div>

        <div class="col-12 jumbotron-norm" *ngIf="!smCoverage && !smErrorCoverage && permSv.hasPermFromV2(allPermissions.jazztel.permite_ventas)">
            <div class="row">
                <div class="col-3" style="align-self: center;" align="center">
                    <img src="./assets/img/jz-login-color.svg" class="img-fluid" width="200">
                </div>
                <div class="col-9">
                    <div class="card-body--digo">
                        <h5 class="mb-0">Lo sentimos, no tienes cobertura</h5>
                        <h5> de fibra a través de la red de Simyo, anímate e inténtalo en Jazztel.</h5>
                        <div>
                            <a href="https://pangea.orange.es" target="_blank" class="btn btn-black--outline mt-3">
                                Acceder a contrataciones {{ pdv?.info?.usuariopangea && pdv?.info?.usuariopangea !== ''
                                ? '(' + pdv?.info?.usuariopangea + ')' : '' }}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="no-coverage" class="col-lg-12 row mt-2" *ngIf="smErrorCoverage">
            <div class="col-auto align-self-center">
                <span aria-hidden="true" class="pl-3">
                    <img src="./assets/img/times-white.svg" class="img-fluid ico-title" width="35">
                </span>
            </div>

            <div class="col my-3 text-center">
                <h5 class="mb-0">Lo sentimos, ha ocurrido un error al realizar las consultas</h5>
                Inténtelo de nuevo más tarde
            </div>
        </div>

        <div class="col-lg-12 mt-2">
            <span (click)="onEdit(true)" class="text-direccion">Comprobar otra dirección</span>
        </div>
    </div>
</section>