<div class="mb-3">
    <div [ngClass]="getCardClass()" [id]="brand.prefix + '--group'">
        <div class="row">
            <div class="{{ brand.isOpen ? 'col' : 'col-12' }} col-xl">
                <!-- Logo -->
                <img [src]="brand.logoPath" [alt]="brand.name" class="img-fluid marca--brand" width="120" [style]="brand.logoStyle">
                <img [src]="brand.logoPath" [alt]="brand.name" class="img-fluid marca--brand--c" width="120" [style]="brand.logoStyle">
                <!-- Banner normalizado -->
                <h4 class="font-weight-bold banner" *ngIf="!brand.isOpen" [innerHTML]="brand.description"></h4>
                <div class="vl--brand"></div>
            </div>

            <div class="{{ brand.isOpen ? 'col-auto' : 'col-12' }} col-xl-auto">
                <button *ngIf="!brand.isOpen" (click)="toggleOpen()" class="btn btn-white-outline--sm" type="button" [class.mt--16]="brand.isOpen"
                        [attr.data-toggle]="brand.canOpen ? 'collapse' : null" [attr.data-target]="brand.canOpen ? '#' + brand.prefix : null" aria-expanded="true"
                        [attr.aria-controls]="brand.canOpen ? 'collapse-' + brand.prefix : null">
                    <b>Acceder</b>
                </button>

                <button *ngIf="brand.isOpen" (click)="toggleOpen()" class="btn px-0" type="button" data-toggle="collapse" [attr.data-target]="'#' + brand.prefix"
                        aria-expanded="true" aria-controls="collapseFour">
                    <img src="./assets/img/x-white.svg" style="margin-top: -30px;" alt="">
                </button>
            </div>
        </div>
    </div>

    <ng-container>
        <div [id]="brand.prefix" class="collapse" [attr.aria-labelledby]="'heading-' + brand.prefix" data-parent="#accordionExample">
            <ng-container *ngFor="let data of brand.data">
                <div class="card-body--brand" *ngIf="data.bodyPrincipalLinks.length > 0 || data.bodySecondaryLinks.length > 0">
                    <div class="row">
                        <div class="col-xl-12">
                            <div>
                                <h6 class="ml-2 marca--sub" *ngIf="data.bodyTitle != null">{{ data.bodyTitle }}</h6>

                                <div class="ml-2 mb-3" *ngIf="data.bodyPrincipalLinks.length > 0">
                                    <ng-container *ngFor="let link of data.bodyPrincipalLinks">
                                        <a *ngIf="!link.isExternal && link.onClick == null" [routerLink]="link.route" class="btn btn-black--outline btn-sm mr-1 mb-1">
                                            <img [src]="link.imagePath" width="12" [alt]="link.label" class="mr-2" *ngIf="link.imagePath != null">
                                            <span>{{ link.label }}</span>
                                        </a>

                                        <a *ngIf="link.isExternal && link.onClick == null" [href]="link.route" [attr.target]="link.isBlank ? '_blank' : null" class="btn btn-black--outline btn-sm mr-1 mb-1">
                                            <img [src]="link.imagePath" width="12" [alt]="link.label" class="mr-2" *ngIf="link.imagePath != null">
                                            <span>{{ link.label }}</span>
                                        </a>

                                        <a *ngIf="link.onClick != null" href="javascript: void(0)" (click)="link.onClick()" class="btn btn-black--outline btn-sm mr-1 mb-1">
                                            <img [src]="link.imagePath" width="12" [alt]="link.label" class="mr-2" *ngIf="link.imagePath != null">
                                            <span>{{ link.label }}</span>
                                        </a>
                                    </ng-container>
                                </div>

                                <div class="ml-2" *ngIf="data.bodySecondaryLinks.length > 0">
                                    <ng-container *ngFor="let link of data.bodySecondaryLinks">
                                        <a *ngIf="!link.isExternal && link.onClick == null" [routerLink]="link.route" class="btn btn-gray--round btn-sm mr-1">
                                            <img [src]="link.imagePath" width="12" [alt]="link.label" class="mr-2" *ngIf="link.imagePath != null">
                                            <span>{{ link.label }}</span>
                                        </a>

                                        <a *ngIf="link.isExternal && link.onClick == null" [href]="link.route" [attr.target]="link.isBlank ? '_blank' : null" class="btn btn-gray--round btn-sm mr-1">
                                            <img [src]="link.imagePath" width="12" [alt]="link.label" class="mr-2" *ngIf="link.imagePath != null">
                                            <span>{{ link.label }}</span>
                                        </a>

                                        <a *ngIf="link.onClick != null" href="javascript: void(0)" (click)="link.onClick()" class="btn btn-gray--round btn-sm mr-1">
                                            <img [src]="link.imagePath" width="12" [alt]="link.label" class="mr-2" *ngIf="link.imagePath != null">
                                            <span>{{ link.label }}</span>
                                        </a>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card-footer--rm" *ngIf="data.footerLinks.length > 0">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="ml-2">
                                <ng-container *ngFor="let link of data.footerLinks">
                                    <a *ngIf="!link.isExternal && link.onClick == null" [routerLink]="link.route" class="black pr-3">
                                        <img [src]="link.imagePath" width="12" [alt]="link.label" class="mr-2" *ngIf="link.imagePath != null">
                                        <span>{{ link.label }}</span>
                                    </a>

                                    <a *ngIf="link.isExternal && link.onClick == null" [href]="link.route" [attr.target]="link.isBlank ? '_blank' : null" class="black pr-3">
                                        <img [src]="link.imagePath" width="12" [alt]="link.label" class="mr-2" *ngIf="link.imagePath != null">
                                        <span>{{ link.label }}</span>
                                    </a>

                                    <a *ngIf="link.onClick != null" href="javascript: void(0)" (click)="link.onClick()" class="black pr-3">
                                        <img [src]="link.imagePath" width="12" [alt]="link.label" class="mr-2" *ngIf="link.imagePath != null">
                                        <span>{{ link.label }}</span>
                                    </a>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <div class="card-body--sm" *ngIf="brand.customMsg">
                <div class="row">
                    <div class="row mx-0">
                        <div class="col-auto">
                            <i-feather name="info" style="width: 48px;"></i-feather>
                        </div>

                        <div class="col pl-0" [innerHTML]="brand.customMsg"></div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>
