import {Component, Input, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HomeService} from '../../services/home.service';
import {Router} from '@angular/router';
import {allLocalStorage, PDV_MULTIMARCA, tabHome} from 'src/app/shared/constantes';
import {PermissionService} from 'src/app/shared/services/permission.service';
import {allPermissions} from 'src/app/shared/permissions';
import {Ipdv} from 'src/app/shared/models/pdvResponse';
import {SimyoTemporaryClosureComponent} from '../../../simyo/components/simyo-temporary-closure/simyo-temporary-closure.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-hm-header',
    templateUrl: './hm-header.component.html',
    styleUrls: ['./hm-header.component.css', '../../../../assets/css/home-theme.css']
})
export class HmHeaderComponent implements OnInit {
    @Input() pdv: Ipdv;

    public baseUrl: string;
    public contractNewBrands: string;
    public contractPepephone: string;
    public brands = {
        republica_movil: 'rm',
        simyo: 'sm',
        orange: 'or',
        amena: 'am'
    };
    public allPermissions = allPermissions;
    public PDV_MULTIMARCA = PDV_MULTIMARCA;
    public FNAC_DISTRIBUTOR: number = environment.production ? 694 : 198;
    public environment = environment;

    constructor(
        public router: Router,
        private homeService: HomeService,
        public permSv: PermissionService,
        private modalService: NgbModal
    ) {
        this.baseUrl = environment.digoEndPoint;
        this.contractNewBrands = environment.contractNewBrands;
        this.contractPepephone = environment.contractPepephone;
    }

    ngOnInit(): void {
    }

    public hiddenMoreOperations(): boolean {
        if (document.getElementById('credit_phone') || document.getElementById('commissions') || document.getElementById('orders') || document.getElementById('free_terminals')) {
            return false;
        }
        return true;
    }

    public viewBrandRates(brand) {
        this.router.navigate(['/']).then(() => {
            this.homeService.setViewBrandRates(brand);
        });
    }

    public goToCommissions() {
        this.router.navigate(['/']).then(() => {
            this.homeService.setGoToTab(tabHome.COMISIONES);
        });
    }

    public signOut() {
        localStorage.removeItem(allLocalStorage.accessToken);
        window.location.href = this.baseUrl + 'signin.itx?ac=999';
    }

    public checkSimyoTemporaryClosure(path: string) {
        if (this.permSv.hasPermFromV2(this.allPermissions.simyo.nbss_block)) {
            this.openSimyoTemporaryClosure();
            return
        }

        this.router.navigate([path]);
    }

    public openSimyoTemporaryClosure() {
        this.modalService.open(SimyoTemporaryClosureComponent, {size: 'lg', centered: true, backdrop: 'static'});
    }

}
