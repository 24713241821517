import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment/moment';
import { Subscription, forkJoin, of } from 'rxjs';
import { allLocalStorage, monthNames, translateCategoryRappel, translationsMonths } from 'src/app/shared/constantes';
import { HomeService } from '../../services/home.service';
import { HmModalMrComponent } from '../hm-modal-mr/hm-modal-mr.component';
import { HmSupportComponent } from '../hm-support/hm-support.component';
import { Chart, BarController, CategoryScale, LinearScale, BarElement, PointElement, Tooltip, Legend, Title, ChartOptions, ChartData, ChartDataset, Filler } from 'chart.js';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { Ipdv } from 'src/app/shared/models/pdvResponse';
import { dayToShowCategoryRappelPreviousMonth, IrappelMaster, IrappelResponse } from '../../models/Irappel';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError, take, tap } from 'rxjs/operators';
import { allPermissions } from 'src/app/shared/permissions';
import { HttpErrorResponse } from '@angular/common/http';

Chart.register(BarController, CategoryScale, LinearScale, BarElement, PointElement, Tooltip, Legend, Title, Filler);

@Component({
    selector: 'app-hm-commissions',
    templateUrl: './hm-commissions.component.html',
    styleUrls: ['./hm-commissions.component.css', '../../../../assets/css/home-theme.css']
})
export class HmCommissionsComponent implements OnInit, OnDestroy {

    @Input() pdv: Ipdv;
    @ViewChild('barCanvasCommissions') private barCanvasCommissions: ElementRef;
    @ViewChild('modalSendReport') modalSendReport: any;
    public barChartCommissions: any;
    public dataSource = [];
    public autoInvoiceData: any;
    public autoInvoiceDataError: boolean = false;
    public commissionsData: any;
    public commissionsDataError: boolean = false;
    public body: any = {
        limit: 12,
        page: 0
    };
    public rappelSimyoData: any;
    public rappelSimyoDataError: boolean = false;
    public displayedColumns: string[] = ['index', 'invoice_number', 'issue_date', 'concepts', 'billing_period', 'base_amount', 'total_amount', 'download'];
    public lastYear = new Date().getFullYear()-1;
    public translateCategoryRappel = translateCategoryRappel;
    public dayToShowCategoryRappelPreviousMonth = dayToShowCategoryRappelPreviousMonth;
    public today = moment();
    private allSubscription: Subscription[] = [];
    public monthNames = monthNames;

    public isSendingReport = false;
    public responseSendingReport = null;

    public allPermissions = allPermissions;

    constructor(
        private homeService: HomeService,
        private matDialog: MatDialog,
        public permSv: PermissionService,
        private modalService: NgbModal
    ) { }

    ngOnInit(): void {
        forkJoin({rappelData: this.homeService.getRappelDataFromService(), rappelBimonthlyData: this.homeService.getRappelBimonthlyDataFromService()})
            .subscribe((data: {rappelData: IrappelResponse, rappelBimonthlyData: IrappelResponse}) => {
                if (data) {
                    if (data.rappelData && data.rappelBimonthlyData) {
                        data.rappelBimonthlyData.current.are_there_activations_in_both_months = false;
                        if (Object.keys(data.rappelBimonthlyData.current.activations_per_month).length) {
                            data.rappelBimonthlyData.current.activations_per_month = Object.entries(data.rappelBimonthlyData.current.activations_per_month).reduce((acc, [key, value]) => {
                                const newKey = translationsMonths[key] || key;
                                acc[newKey] = value as string;
                                return acc;
                            }, {} as Record<string, string>);
                            data.rappelBimonthlyData.current.are_there_activations_in_both_months = Object.values(data.rappelBimonthlyData.current.activations_per_month).every(value => value !== 0);
                        }
                        this.rappelSimyoData = {mensual: data.rappelData, bimensual: data.rappelBimonthlyData};
                    }
                    this.homeService.setRappelSimyo(this.rappelSimyoData);
                    this.homeService.setRappelSimyoError(false);
                } else {
                    this.homeService.setRappelSimyoError(true);
                }
            }, (error: HttpErrorResponse) => {
                this.homeService.setRappelSimyoError(true);
            });

        this.homeService.getInvoicesFromService()
            .subscribe((data: any) => {
                if (data) {
                    data.invoices.map(invoice => {
                        invoice.billing_period = invoice.billing_period.split('+');
                        invoice.concepts = invoice.concepts.split('+');
                    });
                    this.autoInvoiceData = data;
                    this.nextPage();
                }
            }, () => {
                this.autoInvoiceDataError = true;
            });

        this.homeService.getCommisionsFromService()
            .subscribe((data: any) => {
                if (data) {
                    this.commissionsData = data;
                    this.initCharts();
                }
            }, () => {
                this.commissionsDataError = true;
            });

        this.allSubscription.push(this.homeService.getRappelSimyoError()
            .subscribe((data: any) => {
                if (data != null) {
                    this.rappelSimyoDataError = data;
                }
            })
        );

        // Deprecated 01/02/2022
        localStorage.removeItem(allLocalStorage.notificationTecalisCommissions);
    }

    convertDate(data): string {
        return data && data !== '' ? moment(data).format('DD/MM/YYYY') : '';
    }

    convertDateWithMonth(data): string {
        if (data) {
            const dateArray = data.split('/')
            const date = new Date(dateArray[2], dateArray[1]-1, dateArray[0]);
            return data && data !== '' ? monthNames[date.getMonth()] + ' ' + date.getFullYear() : '';
        }
        return '';
    }

    public getWidth(rappel: IrappelResponse): number {
        if (rappel) {
            let maxValueProgressBar: number = 0;
            // La barra de progreso representa el número de activaciones con respecto al mínimo de activaciones del nivel más alto
            maxValueProgressBar = Math.max.apply(Math, rappel.master.map((level: IrappelMaster) => {
                return level.min_activations;
            })) || 20;
            return (rappel.current.current_activations * 100) / maxValueProgressBar;
        }
        return 0;
    }

    nextPage() {
        this.dataSource = this.autoInvoiceData.invoices.slice(0, (this.body.page * this.body.limit) + this.body.limit);
        this.body.page++;
    }

    openMRDialog() {
        this.matDialog.open(HmModalMrComponent, {
            backdropClass: 'md-backdrop',
            maxWidth: 800,
            panelClass: 'md-container-white',
            width: '100%',
            data: {
                autoInvoiceData: this.autoInvoiceData
            }
        });
    }

    openSupportDialog() {
        this.matDialog.open(HmSupportComponent, {
            backdropClass: 'md-backdrop',
            maxWidth: 800,
            minWidth: 500,
            panelClass: 'md-container-white',
            data: {
                pdv: this.pdv
            }
        });
    }

    initCharts() {
        let labels = [];
        let datasetsCommissions: ChartDataset[] = [];
        labels = this.changeDataChart(labels, datasetsCommissions);
        const dataCommissions : ChartData = {
            labels: labels,
            datasets: datasetsCommissions
        };

        this.barChartCommissions = this.createBarChart(this.barCanvasCommissions, dataCommissions);
        this.barChartCommissions.data.datasets = datasetsCommissions;
    }

    changeDataChart(labels, datasetsCommissions) {
        const data = [];
        this.commissionsData.forEach((element, index) => {
            labels.push(monthNames[+element.month - 1]);
            data.push(element.total);
        });
        datasetsCommissions.push({
            label: 'Total',
            backgroundColor: '#ff7900',
            borderColor: '#ff7900',
            data: data
        });
        return labels;
    }

    createBarChart(canvas, data) {
        const options: ChartOptions = {
            responsive: true,
            maintainAspectRatio: false,
            indexAxis: 'y',
            scales: {
                x: {
                    min: 0,
                    stacked: true
                },
                y: {
                    stacked: true,
                    grid: {
                        display: false
                    }
                }
            }
        };
        return new Chart(canvas.nativeElement, {
            type: 'bar',
            data: data,
            options: options
        });
    }

    ngOnDestroy() {
        if (this.allSubscription && this.allSubscription.length) {
            this.allSubscription.map(subs => subs.unsubscribe());
        }
    }

    openModalSendReport() {
        this.modalService.open(this.modalSendReport, {size: 'lg', centered: true, backdrop: 'static', keyboard : false});
    }

    sendReport347() {
        this.isSendingReport = true;
        let messageError = 'Ha ocurrido un error al enviar el informe. Inténtelo más tarde.';
        this.homeService.sendReport347(this.pdv.info.empresa).pipe(
            take(1),
            tap((response: any) => {
                if(response.msg === 'ko') {
                    if(response.error && response.error !== '') {
                        messageError = response.error;
                    }
                    this.responseSendingReport = {code: false, message: messageError};
                    return;
                }
                this.responseSendingReport = {code: true, message: 'El informe se ha enviado correctamente'};
            }),
            catchError(error => {
                this.responseSendingReport = {code: false, message: messageError};
                return of({})
            }),
            tap(() => this.isSendingReport = false)
        ).subscribe();
    }
}
