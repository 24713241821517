import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NormalizadorService } from '../../services/normalizador.service';
import { HmCoverageService } from '../../services/hm-coverage.service';
import { Router} from '@angular/router';
import { SimyoNormalizadorService } from '../../../simyo/services/simyo-normalizador.service';
import { getNameStreet, onChangeZip, searchRoadType } from 'src/app/utils/normalizerFunctions';
import { fiberIndirectTechnologies, realTechnologyNames, tabHome } from 'src/app/shared/constantes';
import { SimyoService } from '../../../simyo/services/simyo.service';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HomeService } from '../../services/home.service';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { Ipdv } from 'src/app/shared/models/pdvResponse';
import {SimyoTemporaryClosureComponent} from '../../../simyo/components/simyo-temporary-closure/simyo-temporary-closure.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {allPermissions} from '../../../shared/permissions';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

@Component({
    selector: 'app-hm-coverage',
    templateUrl: './hm-coverage.component.html',
    styleUrls: ['./hm-coverage.component.css', '../../../../assets/css/home-theme.css']
})
export class HmCoverageComponent implements OnInit, OnDestroy {

    @Input() initCoverage: Observable<any>;
    @Input() pdv: Ipdv;
    @ViewChild('manualAddress') manualAddress;
    @ViewChild('manualAddressText') manualAddressText;
    @ViewChild('formNumber') formNumber: ElementRef;

    public formGroup: FormGroup;
    public country: string = environment.googleMapsCountry;
    public muestraNormalizacion: boolean = false;
    public muestraCobertura: boolean = false;
    // Variable que afecta a la vista del botón
    public muestraBoton: boolean = true;
    public muestraErrorPeticion: boolean = false;
    public isLoading: boolean = false;
    public textDefault: string = 'Normaliza dirección';
    // Datos para el modelo
    private datosTipoVia: any[] = [];
    public datosProvincia: any[] = [];
    public msgErrorPeticion: any;
    public resultadoCobertura: any;
    public smResultadoCobertura: any;

    public smCoverage: boolean = null;
    public smErrorCoverage: any = false;

    public amRealTechnology = [];
    public realTechnologyNames = realTechnologyNames;
    public enviroment = environment;
    public fiberIndirectTechnologies = fiberIndirectTechnologies;

    private changeTabSubscription: Subscription;
    private allSubscription: Subscription[] = [];
    public retryCoverageSubject: Subject<void> = new Subject<void>();
    public allPermissions = allPermissions;

    public myaddress;

    // TechnologyNeba
    public isTechnologyNeba: boolean = false;

    constructor(
        private router: Router,
        protected normalizadorService: NormalizadorService,
        protected smNormalizadorService: SimyoNormalizadorService,
        private coverageService: HmCoverageService,
        private formBuilder: FormBuilder,
        private smService: SimyoService,
        private homeService: HomeService,
        public permSv: PermissionService,
        private modalService: NgbModal,
    ) { }

    ngOnInit(): void {
        this.formGroup = this.formBuilder.group({
            tipoViaNormaliza: [''],
            nombreViaNormaliza: ['', Validators.required],
            numeroNormaliza: ['', Validators.required],
            codigoPostalNormaliza: ['', Validators.required],
            localidadNormaliza: ['', Validators.required],
            provinciaNormaliza: ['', Validators.required]
        });

        this.allSubscription.push(
            this.smService.getProvincesCommunFromService().pipe(
                map((data:any) => {
                    if(data && data.msg) {
                        this.smService.setProvincesCommun(data.msg);
                        return data.msg;
                    }
                    return null;
                }),
                tap((data: any) => {
                    if (data) {
                        this.datosProvincia = data;
                        this.onChangeZip(this.formGroup.get('codigoPostalNormaliza').value);
                    }
                })
            ).subscribe()
        );

        this.allSubscription.push(
            this.smService.getRoadTypesCommun().pipe(
                switchMap((data:any) => {
                    if(data != null) {
                        return of(data);
                    }
                    return this.smService.getRoadTypeCommunFromService().pipe(
                        catchError(error => {
                            return of(null);
                        }),
                        map((data:any) => {
                            if(data && data.msg) {
                                this.smService.setRoadTypesCommun(data.msg);
                                return of(data.msg);
                            }
                            return of(null);
                        })
                    )
                }),
                tap((data: any) => {
                    if (data) {
                        this.datosTipoVia = data;
                    }
                })
            ).subscribe());
        this.formGroup.enable();

        this.allSubscription.push(this.normalizadorService.getMuestraNormalizacion()
            .subscribe((value) => {
                this.muestraNormalizacion = value;

                if (this.muestraNormalizacion) {
                    this.formGroup.enable();
                    this.formGroup.get('provinciaNormaliza').disable();
                }
            })
        );

        this.allSubscription.push(this.normalizadorService.getMuestraCobertura()
            .subscribe((value) => {
                this.muestraCobertura = value;
            })
        );

        // Afecta a la visibilidad del botón de envío
        this.allSubscription.push(this.normalizadorService.getMuestraBotonNormalizacion()
            .subscribe((value) => {
                this.muestraBoton = value;
            })
        );

        // Errores petición http o direcciones vacias
        this.allSubscription.push(this.normalizadorService.getErrorPeticionNormaliza()
            .subscribe((value) => {
                this.muestraErrorPeticion = value;
            })
        );

        this.allSubscription.push(this.normalizadorService.getMsgErrorNormaliza()
            .subscribe((value) => {
                this.msgErrorPeticion = value;
            })
        );

        this.allSubscription.push(this.normalizadorService.getResultadoCobertura()
            .subscribe((valor) => {
                if (valor) {
                    this.resultadoCobertura = valor;
                    if (this.resultadoCobertura.getRawValue() && this.resultadoCobertura.getRawValue().resultadoConsultaCobertura && this.resultadoCobertura.getRawValue().resultadoConsultaCobertura.realtechnology) {
                        this.amRealTechnology = this.resultadoCobertura.getRawValue().resultadoConsultaCobertura.realtechnology;
                    }
                }
            })
        );

        this.allSubscription.push(this.smNormalizadorService.getResultadoCobertura()
            .subscribe((valor) => {
                if (valor) {
                    this.smResultadoCobertura = valor;
                }
            })
        );

        this.allSubscription.push(this.coverageService.getSmCoverage()
            .subscribe((data: any) => {
                this.smCoverage = data;
            })
        );

        this.allSubscription.push(this.coverageService.getSmErrorCoverage()
            .subscribe((data: any) => {
                this.smErrorCoverage = data;
            })
        );

        this.changeTabSubscription = this.initCoverage
            .subscribe((data) => {
                if (data?.tab?.textLabel === tabHome.COBERTURA) {
                    this.onEdit(true);
                }
            });
    }

    onAutocompleteSelected($event) {
        const addressComponents: any[] = $event.address_components;

        if (addressComponents != null && addressComponents.length > 0) {
            this.formGroup.get('tipoViaNormaliza').patchValue('');
            this.formGroup.get('nombreViaNormaliza').patchValue('');
            this.formGroup.get('numeroNormaliza').patchValue('');
            this.formGroup.get('codigoPostalNormaliza').patchValue('');
            this.formGroup.get('localidadNormaliza').patchValue('');
            this.formGroup.get('provinciaNormaliza').patchValue('');

            for (const addressComponent of addressComponents) {
                let field = '';
                const types: string[] = addressComponent.types;
                let value: string = addressComponent.long_name;

                if (types.includes('route')) {
                    field = 'nombreViaNormaliza';
                    value = searchRoadType(value, this.datosTipoVia, 'value', this.formGroup.get('tipoViaNormaliza'));
                } else if (types.includes('street_number')) {
                    field = 'numeroNormaliza';
                } else if (types.includes('postal_code')) {
                    field = 'codigoPostalNormaliza';
                    this.onChangeZip(value);
                } else if (types.includes('locality')) {
                    field = 'localidadNormaliza';
                } else if (types.includes('administrative_area_level_2')) {
                    field = 'provinciaNormaliza';
                    value = value.toUpperCase();
                }

                if (field !== '') {
                    this.formGroup.get(field).patchValue(value);
                }
            }
        }
        if (this.formGroup.get('numeroNormaliza').value === '') {
            this.formNumber.nativeElement.focus();
        }
    }

    onChangeZip(value) {
        if (value && +value > 99999) {
            value = value.substring(0,5);
            this.formGroup.get('codigoPostalNormaliza').patchValue(value);
        }
        onChangeZip(value, this.datosProvincia, 'postal_code', this.formGroup.get('provinciaNormaliza'));
    }

    getTypeStreet(value: string): string {
        if (this.datosTipoVia && this.datosTipoVia.length && value) {
            if (value.toLowerCase() === 'passatge') {
                value = 'pasaje';
            }
            const valueNormalized = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

            const tipoVia = this.datosTipoVia.filter(item => {
                const codeNormalized = item.value.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');

                return valueNormalized.toLowerCase() === codeNormalized.toLowerCase();
            });

            if (tipoVia && tipoVia.length) {
                const code = tipoVia[0].value;

                return code;
            } else {
                return 'CALLE';
            }
        }

        return 'CALLE';
    }

    public errorHandling = (control: string, error: string) => {
        if (this.formGroup.get(control).invalid && (this.formGroup.get(control).dirty || this.formGroup.get(control).touched)) {
            return this.formGroup.controls[control].hasError(error);
        }
    }

    onNormalizarDireccion() {
        this.normalizadorService.setErrorPeticionNormaliza(false);
        this.normalizadorService.setMsgErrorNormaliza('');

        if (!this.formGroup.get('tipoViaNormaliza').value || this.formGroup.get('tipoViaNormaliza').value === '') {
            searchRoadType(this.formGroup.get('nombreViaNormaliza').value, this.datosTipoVia, 'value', this.formGroup.get('tipoViaNormaliza'));
        }

        this.validateAllFormFields(this.formGroup);
        if (this.formGroup.valid) {
            this.onChangeLoading(true);
            this.formGroup.disable();
            const body = this.getDataNormalizar();
            this.normalizadorService.normalizarHorizontal(body)
                .subscribe((data: any) => {
                    if (Array.isArray(data) && data.length) {
                        data = data.filter(address => address.vertical_addresses && address.vertical_addresses.length);
                    }
                    if (Array.isArray(data) && data.length) {
                        this.normalizadorService.setMuestraBotonNormalizacion(false);
                        this.normalizadorService.setMuestraCobertura(true);
                        this.normalizadorService.setDatosNormalizacion(data);
                        this.normalizadorService.setLocalidades(data);
                        this.normalizadorService.setFormularioNormalizacion(this.formGroup);
                        this.formGroup.disable();
                        this.onChangeLoading(false);
                    } else {
                        this.normalizadorService.setErrorPeticionNormaliza(true);
                        this.normalizadorService.setMsgErrorNormaliza(data && data.msg && data.msg !== '' ? data.msg : 'No existen resultados para los datos indicados.');
                        this.onChangeLoading(false);
                        this.formGroup.enable();
                    }
                }, (error) => {
                    this.normalizadorService.setErrorPeticionNormaliza(true);
                    this.normalizadorService.setMsgErrorNormaliza(error.error.error.msg);
                    this.onChangeLoading(false);
                    this.formGroup.enable();
                });
        } else {
            const elements: any = document.getElementsByClassName('mat-input-element ng-invalid');
            if (elements.length > 0) {
                elements[0].focus();
            }
            return;
        }
    }

    onChangeLoading(state) {
        if (state === true) {
            this.isLoading = true;
            this.textDefault = 'Normalizando ...';
        } else {
            this.isLoading = false;
            this.textDefault = 'Normalizar dirección';
        }
    }

    getDataNormalizar() {
        return {
            streetNr: `${this.formGroup.value.numeroNormaliza}`,
            streetName: getNameStreet(this.formGroup.value.nombreViaNormaliza, this.datosTipoVia, 'value'),
            city: this.formGroup.value.localidadNormaliza,
            stateOrProvince: this.formGroup.value.provinciaNormaliza,
            streetType: this.getTypeStreet(this.formGroup.value.tipoViaNormaliza),
            postCode: `${this.formGroup.value.codigoPostalNormaliza}`
        };
    }

    validateAllFormFields(formGroup: FormGroup | FormArray): boolean {
        const keysArray = formGroup instanceof FormGroup ? Object.values(formGroup.controls) : formGroup.controls;
        keysArray.forEach(control => {
            if (control instanceof FormControl) {
                control.markAsTouched({onlySelf: false});
                control.updateValueAndValidity();
            } else if (control instanceof FormGroup || control instanceof FormArray) {
                this.validateAllFormFields(control);
            }
        });
        return formGroup.invalid;
    }

    isNumber(value: string): any {
        return isNaN(Number(value)) ? value : Number(value);
    }

    includeFloor(value : string) : string {
        let returnValue = '';
        if (typeof value === 'string' && !value.toLowerCase().includes('planta')) {
            returnValue = 'Planta';
        }
        return returnValue;
    }

    onEdit(clean: boolean = false) {
        // if (clean) {
        //     this.formGroup.reset();
        // }

        if(this.formGroup.valid) {
            this.normalizadorService.setFormularioNormalizacion(this.formGroup);
            this.normalizadorService.setMuestraCobertura(true);
        } else {
            this.normalizadorService.setMuestraCobertura(false);
        }

        this.normalizadorService.setMuestraNormalizacion(true);
        this.normalizadorService.setMuestraBotonNormalizacion(true);
        this.coverageService.setSmCoverage(null);
    }

    goToSimyo(type: string) {
        if (this.permSv.hasPermFromV2(this.allPermissions.simyo.nbss_block)) {
            this.modalService.open(SimyoTemporaryClosureComponent, {size: 'lg', centered: true, backdrop: 'static'});
            return
        }

        const path = type === 'convergent' ? 'simyo/convergente' : 'simyo/solo-fibra';
        const forms = {
            fibra: null,
            movilPacks: null,
            cliente: null,
            normalizador: this.formGroup.getRawValue(),
            cobertura: this.smResultadoCobertura.getRawValue(),
            haveCoverage: true
        };

        this.smService.setTypeOrder(type);
        this.smService.setFormsData(forms);
        this.smNormalizadorService.setMuestraNormalizacion(false);
        this.smNormalizadorService.setMuestraBotonNormalizacion(false);
        this.smNormalizadorService.setMuestraCobertura(true);
        this.smNormalizadorService.setThereIsCoverage(true);
        this.smNormalizadorService.setResultadoCobertura(null);
        this.router.navigate([path]);
    }

    retryCheckCoverage() {
        this.retryCoverageSubject.next();
    }

    ngOnDestroy() {
        this.changeTabSubscription.unsubscribe();
        if (this.allSubscription && this.allSubscription.length) {
            this.allSubscription.map(subs => subs.unsubscribe());
        }
    }

    onAddressChange(event) {
        setTimeout(() => {
            const text = event.target.value;
            const autocompleteBoxes = document.getElementsByClassName('pac-container pac-logo');
            let autocompleteBox = autocompleteBoxes[autocompleteBoxes.length - 1];

            for (let i = 0; i < autocompleteBoxes.length; i++) {
                if (autocompleteBoxes.item(i).clientWidth > 0 && autocompleteBoxes.item(i).innerHTML) {
                    autocompleteBox = autocompleteBoxes.item(i);
                    break;
                }
            }

            if (text && text !== '') {
                this.manualAddress.nativeElement.style.display = 'flex';
                this.manualAddress.nativeElement.style.top = 'calc(100% + ' + (autocompleteBox.clientHeight - 20) + 'px)';
                this.manualAddressText.nativeElement.innerText = text;
            } else {
                this.onAddressFocusOut();
            }
        }, 300)
    }

    sendEvent(event) {
        setTimeout(() => {
            const text = event.target.value;
            if (text && text !== '') {
                this.homeService.sendIdSessionToService('HM');
            }
        }, 300)
    }

    onAddressFocusOut() {
        setTimeout(() => {
            this.manualAddress.nativeElement.style.display = 'none';
            this.manualAddress.nativeElement.style.top = '100%';
            this.manualAddressText.nativeElement.innerText = '';
        }, 100)
    }

    myAddressSelected(address){
        this.myaddress = address;

        if (!address?.detail?.ospGeographicAddress?.length) {
            return;
        }
        this.formGroup.patchValue({
            tipoViaNormaliza: address?.detail?.ospGeographicAddress[0]?.streetType,
            nombreViaNormaliza: address?.detail?.ospGeographicAddress[0]?.streetName,
            numeroNormaliza: address?.detail?.ospGeographicAddress[0]?.streetNr,
            codigoPostalNormaliza: address?.detail?.ospGeographicAddress[0]?.postCode,
            localidadNormaliza: address?.detail?.ospGeographicAddress[0]?.city,
            provinciaNormaliza: address?.detail?.ospGeographicAddress[0]?.stateOrProvince
        })

        this.formGroup.patchValue({
            rawAddress: address?.detail,
            localidadCobertura: address?.detail?.ospGeographicAddress[0]?.city,
            direccionesCobertura: address?.detail?.googleInfo?.formatted_address,
            tipoViaCobertura: address?.detail?.ospGeographicAddress[0]?.streetType,
            nombreViaCobertura: address?.detail?.ospGeographicAddress[0]?.streetName,
            numeroCobertura: address?.detail?.ospGeographicAddress[0]?.streetNr,
            provinciaCobertura: address?.detail?.ospGeographicAddress[0]?.stateOrProvince,
            codigoPostalCobertura: address?.detail?.ospGeographicAddress[0]?.postCode,

            bisCobertura: address?.detail?.ospGeographicAddressTR?.bis_TR,
            bloqueCobertura: address?.detail?.ospGeographicAddressTR?.bloque_TR,
            escaleraCobertura: address?.detail?.ospGeographicAddressTR?.escalera_TR,

            // portalCobertura: address?.detail?.ospGeographicAddressTR?.puerta_TR,
            // tipoPuertaCobertura: address?.detail?.ospGeographicAddressTR?.city,
            // numeroApartamento: address?.detail?.ospGeographicAddressTR?.city,

            plantaCobertura: address?.detail?.ospGeographicAddressTR?.planta_TR,
            puertaCobertura: address?.detail?.ospGeographicAddressTR?.puerta_TR,

            resultadoConsultaCobertura: address?.detail?.ospGeographicAddressTR?.city,
        });

        if(this.formGroup.valid) {
            this.normalizadorService.setFormularioNormalizacion(this.formGroup);
            this.normalizadorService.setMuestraCobertura(true);
        } else {
            this.normalizadorService.setMuestraCobertura(false);
        }
    }
}
